import { ActivityEvent, Campaign, MemberCampaignFocus, MemberCampaignProgress, MemberPlan, MemberQuest, MemberQuestCalculation, MemberQuestDisplay, MemberQuestProgress, MemberQuestReward, Quest, SS_MemberPlan, Saga } from "./challengeTypes";
import { GraphQLResult } from "../services/hcpEligibilityService";
import { MemberProgramRegistration, MAPSPMapping, MAPSGMapping, PlanData, GroupData, SS_MemberProgramRegistration } from "./keystoneTypes";

export type PRDashboardProps = {
    inputToken: {
        value: string;
    };
};

export type SearchResponse = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    M: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MMD: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MD: any[];
    MP: MemberPlan[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    VOID_MP: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RR: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ER: any[];
    SD: Saga[];
    CD: Campaign[];
    MCF: MemberCampaignFocus[];
    MCP: MemberCampaignProgress[];
    QD: Quest[];
    MQ: MemberQuest[];
    MQD: MemberQuestDisplay[];
    MQC: MemberQuestCalculation[];
    MQP: MemberQuestProgress[];
    MQR: MemberQuestReward[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DE: any[];
    AE: ActivityEvent[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GT: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AR: any[];
    MPR: MemberProgramRegistration[];
    // MPA: MemberPlanAssociation[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    EIMP?: any;
    // Eligibility data from HCP API
    Eligibility?: GraphQLResult[];
    // MAPSPMapping data
    MAPSPMapping?: MAPSPMapping[];
    // MAPSGMapping data
    MAPSGMapping?: MAPSGMapping[];
    // P data (plans from programId)
    P?: PlanData[];
    // G data (groups from groupId)
    G?: GroupData[];
    Count: number;
    ScannedCount: number;
};

export enum MemberIdType {
    EID = 'EID',
    RALLY_ID = 'RallyID',
    NAVI_ID = 'NaviID',
    HSID = 'HSID',
    HHID = 'HHID',
    MBI = 'MBI'
};

export type MemberIdModel = {
    enterpriseId?: string;
    hasER?: boolean;
    rallyId?: string;
    hasRR?: boolean;
    hasRRSK?: boolean;
    hsidUsername?: string;
    hsidUuid?: string;
    naviId: string;
};

export type DeviceTypePairDates = {
    dataType?: string;
    partner?: string;
    firstPairDate?: string;
    latestPairDate?: string;
};

export type DemographicsModel = {
    firstName?: string;
    lastName?: string;
    dob?: string;
    registeredDate?: string;
    gender?: string;
    policyId?: string;
    planName?: string;
    lob?: string;
    programStartDate?: string;
    utcOffset?: number;
};

export type LastActivityReceived = {
    dataType?: string;
    partner?: string;
    date?: string;
};

export type LastCompletedQuest = {
    name?: string;
    date?: string;
    sk?: string;
};

export type MemberAdditionalData = {
    lastActivityReceived?: LastActivityReceived;
    lastSyncDate?: string;
    lastCompletedQuest?: LastCompletedQuest;
    assignedUpToDate?: string;
};

export type MemberSearchResultsModel = {
    memberIds: MemberIdModel;
    deviceTypePairDates: DeviceTypePairDates[];
    demographics: DemographicsModel;
    additionalData: MemberAdditionalData;
    plans: SS_MemberPlan[];
};

export enum KeystoneIdType {
    EID = 'EID',
    HHID = 'HHID',
    MBI = 'MBI',
};

export enum DefaultTab {
    MemberSearch = 0,
    RawData = 1,
    KeystoneSearch = 2
};

export interface GroupDataModel {
    groupNumber: string;
    masterGroup?: string;
    startDate?: string;
    endDate?: string;
    groupMappingData?: MAPSGMapping; // MAPSGMapping data
    groupData?: GroupData; // G data
}

export interface PlanGroupDataModel {
    contractId: string;
    pbp: string;
    segmentId: string;
    effectiveDate: string;
    terminationDate: string;
    planMappingData?: MAPSPMapping; // MAPSPMapping data
    planData?: PlanData; // P data
    groups: GroupDataModel[];
}

export type KeystoneSearchResultsModel = {
    memberProgramRegistrations: SS_MemberProgramRegistration[];
    eligibilityData: GraphQLResult[];
    planGroupData: PlanGroupDataModel[];
};
