import React from 'react'

import { DefaultTab } from '../constants/types';

interface UserPreferences {
  compactDeveloperColumns: boolean;
  defaultTab: DefaultTab;
  toggleCompactDeveloperColumns: () => void;
  setDefaultTab: (tab: DefaultTab) => void;
}

const initialState: UserPreferences = {
  compactDeveloperColumns: true,
  defaultTab: DefaultTab.MemberSearch,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleCompactDeveloperColumns: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDefaultTab: () => {}
}

// https://vimalselvam.com/post/toggle-theme-using-react-hooks/
const UserPreferenceContext = React.createContext(initialState)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function UserPreferenceProvider({ children } : any) {
    const [compactDeveloperColumns, setCompactDeveloperColumns] = React.useState(true);
    const [defaultTab, setDefaultTabState] = React.useState(DefaultTab.MemberSearch);

    React.useEffect(() => {
        const isCompactDeveloperColumns = localStorage.getItem('compactDeveloperColumns') === 'true';
        setCompactDeveloperColumns(isCompactDeveloperColumns);

        const savedTab = localStorage.getItem('defaultTab');
        if (savedTab !== null) {
            setDefaultTabState(parseInt(savedTab));
        }
    }, [compactDeveloperColumns, defaultTab])

    const toggleCompactDeveloperColumns = () => {
        const isCompactDeveloperColumns = !compactDeveloperColumns;
        localStorage.setItem('compactDeveloperColumns', JSON.stringify(isCompactDeveloperColumns));
        setCompactDeveloperColumns(isCompactDeveloperColumns);
    }

    const setDefaultTab = (tab: DefaultTab) => {
        localStorage.setItem('defaultTab', tab.toString());
        setDefaultTabState(tab);
    };

    return (
        <UserPreferenceContext.Provider value={{
            compactDeveloperColumns,
            toggleCompactDeveloperColumns,
            defaultTab,
            setDefaultTab
        }}>
            {children}
        </UserPreferenceContext.Provider>
    )
}

export { UserPreferenceProvider, UserPreferenceContext }
