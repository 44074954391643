import axios, { AxiosError } from 'axios';
import { MemberIdType } from '../constants/types';

export interface SourceIdentifier {
  linking_authority?: string;
  type: string;
  value: string[];
  sourceCode: string;
}

export interface OtherIdentifier {
  linking_authority?: string;
  type: string;
  value: string;
  sourceCode: string;
}

export interface RallyIdentifier {
  linking_authority?: string;
  rallyID: string;
}

export interface PersonIdentifier {
  linking_authority?: string;
  personId: string;
}

export interface GpsHouseholdIdsInfo {
  values: string[];
  sourceCode: string;
}

export interface MemberInfo {
  identifiers?: {
    hsid_identifiers?: Array<{ hsid: string }>;
    rally_identifiers?: RallyIdentifier[];
    identity_personid?: PersonIdentifier[];
    source_identifiers?: SourceIdentifier[];
    other_ids?: OtherIdentifier[];
  };
  name?: {
    firstName?: string;
    lastName?: string;
  };
  dateOfBirth?: string;
  gender?: string;
  // Helper properties for easy access
  rallyId?: string;
  personId?: string;
  gpsHouseholdIds?: GpsHouseholdIdsInfo;
  medicareBeneficiaryIdentifier?: string;
  [key: string]: unknown;
}

export interface EimpSearchResult {
  status: 'success' | 'error';
  message: string;
  data?: {
    enterpriseId: string;
    memberInfo: MemberInfo;
  };
}

/**
 * Search for a member using the EIMP API
 * @param token The authentication token
 * @param idType The type of ID being searched
 * @param idValue The ID value to search for
 * @returns A promise that resolves to the EIMP search result
 */
export const searchEimp = async (
  token: string,
  idType: MemberIdType,
  idValue: string
): Promise<EimpSearchResult> => {
  try {
    const eimpResponse = await axios.post(
      `${process.env.REACT_APP_INVOKE_URL}/eimp/search`,
      `idType=${idType}&idValue=${idValue}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    
    return eimpResponse.data;
  } catch (error) {
    let errorMessage = 'Unknown error occurred';
    if (axios.isAxiosError(error)) {
      errorMessage = (error as AxiosError).message;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    
    return {
      status: 'error',
      message: `Error searching by ${idType}: ${errorMessage}`
    };
  }
};

/**
 * Get member details from EIMP API by Enterprise ID
 * @param token The authentication token
 * @param enterpriseId The Enterprise ID to search for
 * @returns A promise that resolves to the EIMP search result
 */
export const getMemberByEnterpriseId = async (
  token: string,
  enterpriseId: string
): Promise<EimpSearchResult> => {
  return searchEimp(token, MemberIdType.EID, enterpriseId);
};

/**
 * Get member details from EIMP API by HSID
 * @param token The authentication token
 * @param hsid The HSID to search for
 * @returns A promise that resolves to the EIMP search result
 */
export const getMemberByHsid = async (
  token: string,
  hsid: string
): Promise<EimpSearchResult> => {
  return searchEimp(token, MemberIdType.HSID, hsid);
};

/**
 * Get member details from EIMP API by Rally ID
 * @param token The authentication token
 * @param rallyId The Rally ID to search for
 * @returns A promise that resolves to the EIMP search result
 */
export const getMemberByRallyId = async (
  token: string,
  rallyId: string
): Promise<EimpSearchResult> => {
  return searchEimp(token, MemberIdType.RALLY_ID, rallyId);
};

/**
 * Get member details from EIMP API by Household ID
 * @param token The authentication token
 * @param hhid The Household ID to search for
 * @returns A promise that resolves to the EIMP search result
 */
export const getMemberByHouseholdId = async (
  token: string,
  hhid: string
): Promise<EimpSearchResult> => {
  return searchEimp(token, MemberIdType.HHID, hhid);
};

/**
 * Get member details from EIMP API by Medicare Beneficiary ID
 * @param token The authentication token
 * @param mbi The Medicare Beneficiary ID to search for
 * @returns A promise that resolves to the EIMP search result
 */
export const getMemberByMbi = async (
  token: string,
  mbi: string
): Promise<EimpSearchResult> => {
  return searchEimp(token, MemberIdType.MBI, mbi);
};

/**
 * Process an EIMP result to extract and organize important identifiers
 * @param memberInfo The raw member info from EIMP
 * @returns The processed member info with easily accessible identifiers
 */
export const processEimpResult = (memberInfo: MemberInfo): MemberInfo => {
  // Create a copy of the member info to avoid modifying the original
  const processedInfo = { ...memberInfo };
  
  // Extract rallyId from rally_identifiers if available
  if (processedInfo.identifiers?.rally_identifiers?.length) {
    processedInfo.rallyId = processedInfo.identifiers.rally_identifiers[0].rallyID;
  }
  
  // Extract personId from identity_personid if available
  if (processedInfo.identifiers?.identity_personid?.length) {
    processedInfo.personId = processedInfo.identifiers.identity_personid[0].personId;
  }
  
  // Extract gpsHouseholdIds from source_identifiers if available
  const gpsHouseholdIdentifiers = processedInfo.identifiers?.source_identifiers?.filter(
    identifier => identifier.type === 'gpsHouseholdIds'
  );
  
  if (gpsHouseholdIdentifiers?.length) {
    processedInfo.gpsHouseholdIds = {
      values: gpsHouseholdIdentifiers[0].value,
      sourceCode: gpsHouseholdIdentifiers[0].sourceCode
    };
    console.log('Retrieved gpsHouseholdIds:', processedInfo.gpsHouseholdIds);
  }
  
  // Extract medicareBeneficiaryIdentifier from other_ids if available
  const mbiIdentifiers = processedInfo.identifiers?.other_ids?.filter(
    identifier => identifier.type === 'MedicareBeneficiaryIdentifier'
  );
  
  if (mbiIdentifiers?.length) {
    processedInfo.medicareBeneficiaryIdentifier = mbiIdentifiers[0].value;
    console.log('Retrieved MBI:', processedInfo.medicareBeneficiaryIdentifier);
  }
  
  return processedInfo;
};
