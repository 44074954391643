import React, { useMemo } from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { Table } from '@abyss/web/ui/Table';
import { SS_Campaign, SS_MemberQuest } from '../constants/challengeTypes';
import SubChallengeViewQuestRow from './SubChallengeViewQuestRow';
import { Cell, HeaderCell, HeaderRow } from '../styles/styles';

export interface SubChallengeViewProps {
    campaign: SS_Campaign;
    quest: SS_MemberQuest;
};

const SubChallengeView: React.FC<SubChallengeViewProps> = ({ campaign, quest }) => {
    const [isChildDay, isDailyFitnessParent] = useMemo(() => {
        const isChildDay = quest.quests.length > 0 && quest.quests[0].qd?.duration === 'Day';
        const isDailyFitnessParent = quest.qd?.trackerType === 'Daily' && quest.qd?.calculationActivity === 'QuestCompletion' && quest.qd?.category === 'Fitness';
        return [isChildDay, isDailyFitnessParent];
    }, [quest]);

    const isDailyDefault = campaign.campaignType === 'DailyDefault';

    return (
        <>
            <Heading textAlign='left' offset={5} color='$interactive1'>Sub Challenges</Heading>
            {!isDailyDefault && quest.quests.length > 2 &&
                <Table.Container title="Sub Challenge Summary">
                    <Table.TableHeader>
                        <HeaderRow $transparent>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell>Quest Type</HeaderCell>
                            <HeaderCell>Goal</HeaderCell>
                            <HeaderCell>Duration</HeaderCell>
                            <HeaderCell>Sub challenge count</HeaderCell>
                        </HeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                        <Table.Row>
                            <Cell>{quest.quests[0].qd?.name}</Cell>
                            <Cell>{quest.quests[0].qd?.calculationActivity}</Cell>
                            <Cell>{quest.quests[0].qd?.goal}</Cell>
                            <Cell>{quest.quests[0].qd?.duration}</Cell>
                            <Cell>{quest.quests[0].qd?.calculationRequirements?.dependentMemberQuestProgressIds.length ?? 0}</Cell>
                        </Table.Row>
                    </Table.TableBody>
                </Table.Container>
            }
            <Table.Container title="Sub Challenge Details">
                <Table.TableHeader>
                    <HeaderRow>
                        {(isDailyDefault || isDailyFitnessParent) && <>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell>Quest Type</HeaderCell>
                            <HeaderCell>Goal</HeaderCell>
                        </>}
                        {isChildDay && <>
                            <HeaderCell>Date</HeaderCell>
                        </>}
                        {!isChildDay && <>
                            <HeaderCell>Start Date</HeaderCell>
                            <HeaderCell>End Date</HeaderCell>
                        </>}
                        <HeaderCell>Completed Date</HeaderCell>
                        <HeaderCell>Rewarded Date</HeaderCell>
                        <HeaderCell>Last Calculated Date</HeaderCell>
                        <HeaderCell>Progress</HeaderCell>
                        <HeaderCell></HeaderCell>
                    </HeaderRow>
                </Table.TableHeader>
                <Table.TableBody>
                    {quest.quests.map((q2, j) => (
                        <SubChallengeViewQuestRow quest={q2} campaign={campaign} key={j} />
                    ))}
                </Table.TableBody>
            </Table.Container>
        </>
    );
};

export default SubChallengeView;
