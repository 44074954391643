import { Table } from '@abyss/web/ui/Table';
import { SS_MemberProgramRegistration } from '../constants/keystoneTypes';

interface MemberProgramRegistrationTableProps {
    registrations: SS_MemberProgramRegistration[];
}

const columns = [
    { key: 'id', name: 'EID' },
    { key: 'rewardsProgramId', name: 'Rewards Program ID' },
    { key: 'rewardsEffectiveDate', name: 'Rewards Effective Date' },
    { key: 'rewardsTermDate', name: 'Rewards Term Date' },
    { key: 'createdDateTime', name: 'Created Date' },
    { key: 'lineOfBusiness', name: 'Line of Business' },
];

const MemberProgramRegistrationTable: React.FC<MemberProgramRegistrationTableProps> = ({ registrations }) => {
    const rows = registrations.map((reg) => ({
        id: reg.pkEid,
        createdDateTime: reg.createdDateTime,
        eligibilityEffectiveDate: reg.eligibilityEffectiveDate,
        lineOfBusiness: reg.lineOfBusiness,
        rewardsEffectiveDate: reg.rewardsEffectiveDate,
        rewardsTermDate: reg.rewardsTermDate,
        preEffectiveDays: reg.preEffectiveDays,
        postEffectiveDays: reg.postEffectiveDays,
        rewardsProgramId: reg.skProgramId,
    }));

    return (
        <Table
            title="Member Program Registrations"
            columns={columns}
            rows={rows}
        />
    );
};

export default MemberProgramRegistrationTable;
