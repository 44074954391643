import React, { useMemo } from 'react';
import { Table } from '@abyss/web/ui/Table';
import { ActivityEvent } from '../constants/challengeTypes';
import { sortDeveloperViewHeaders, trimQuotes } from '../helpers/challengeUtils';
import InlinePopover from './InlinePopover';
import CopyButton from './CopyButton';
import { Cell, HeaderCell, HeaderRow, TableWrapper } from '../styles/styles';

export interface SubChallengeActivityEventDeveloperRowProps {
    activities: ActivityEvent[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyValue = [string, any];
type ActivityRow = KeyValue[];

const SubChallengeActivityEventDeveloperRow: React.FC<SubChallengeActivityEventDeveloperRowProps> = ({ activities }) => {
    const entries: ActivityRow[] = useMemo(() => {
        return activities.map((activity) =>
            Object.entries(activity.row)
            .sort((a, b) => sortDeveloperViewHeaders(a[0], b[0]))
            .map((kv) => [kv[0], trimQuotes(JSON.stringify(kv[1]))])
        );
    }, [activities]);

    if(entries.length > 0) {
        entries[0][0][0] = 'pk.AE';
    }

    return (
        <TableWrapper>
            <Table.Container title="Activity Events Table">
                <Table.TableHeader>
                    <HeaderRow>
                        {entries[0].map((columnValuePair, i) => (
                            <HeaderCell key={i}>{columnValuePair[0]}</HeaderCell>
                        ))}
                    </HeaderRow>
                </Table.TableHeader>
                <Table.TableBody>
                    {entries.map((ar, i) =>
                        <Table.Row key={i}>
                            {ar.map((columnValuePair, j) => {
                                let value = <>{columnValuePair[1]}</>;
                                if (j === 0) {
                                    const components = columnValuePair[1].split('#');
                                    if (components[0] === 'AE') {
                                        value = <>{components[0]}#RID#{components[2]}<InlinePopover width={550} content={<>{components}<CopyButton value={columnValuePair[1]} /></>}/></>;
                                    }
                                }
                                return (<Cell key={j}>{value}</Cell>)
                            })}
                        </Table.Row>
                    )}
                </Table.TableBody>
            </Table.Container>
        </TableWrapper>
    );
};

export default SubChallengeActivityEventDeveloperRow;
