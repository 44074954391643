import axios, { AxiosError } from 'axios';
import qs from 'qs';
import { KeystoneIdType } from '../constants/types';

interface WorkflowTriggerResponse {
  data: {
    data: {
      attributes: Record<string, unknown>;
      id: string;
      type: string;
    };
    meta: {
      workflowId: string;
    };
  };
}

interface WorkflowStatusResponse {
  data: {
    data: {
      attributes: {
        instanceStatus: {
          detailsKind: string;
          displayName: string;
        };
        endTimestamp?: string;
        startTimestamp: string;
      };
      id: string;
      type: string;
    };
  };
}

/**
 * Service for interacting with Datadog Workflows
 */

/**
 * Trigger a Datadog Workflow
 * @param workflowId - The ID of the workflow to trigger
 * @param token - The authorization token
 * @param params - Optional parameters to pass to the workflow
 * @returns Promise with the workflow execution response
 */
interface WorkflowParams {
  id_csv_list: string;
  id_type: KeystoneIdType;
  send_email?: boolean;
}

export const triggerDatadogWorkflow = async (
  workflowId: string,
  token: string,
  params: WorkflowParams
): Promise<{ success: boolean; data?: WorkflowTriggerResponse; error?: string }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_INVOKE_URL}/datadog/workflows/${workflowId}/instances`,
      qs.stringify({ ...params, send_email: params.send_email ?? true }),
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error triggering Datadog workflow:', error);
    const message = axios.isAxiosError(error) 
      ? ((error as AxiosError).response?.data as string) || error.message
      : 'Unknown error occurred';
    return { success: false, error: message };
  }
};

/**
 * Get the status of a Datadog Workflow execution
 * @param executionId - The ID of the workflow execution
 * @param token - The authorization token
 * @returns Promise with the workflow execution status
 */
export const getWorkflowExecutionStatus = async (
  workflowId: string,
  executionId: string,
  token: string
): Promise<{ success: boolean; data?: WorkflowStatusResponse; error?: string }> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_INVOKE_URL}/datadog/workflows/${workflowId}/instances/${executionId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error getting workflow execution status:', error);
    const message = axios.isAxiosError(error)
      ? ((error as AxiosError).response?.data as string) || error.message
      : 'Unknown error occurred';
    return { success: false, error: message };
  }
};
