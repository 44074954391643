import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Table } from '@abyss/web/ui/Table';
import { Button } from '@abyss/web/ui/Button';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { SS_MemberQuest } from '../constants/challengeTypes';
import { sortDeveloperViewHeaders, trimQuotes } from '../helpers/challengeUtils';
import SubChallengeDeveloperRow from './SubChallengeDeveloperRow';
import SubChallengeQuestDeveloperRow from './SubChallengeQuestDeveloperRow';
import SubChallengeActivityEventDeveloperRow from './SubChallengeActivityEventDeveloperRow';
import InlinePopover from './InlinePopover';
import CopyButton from './CopyButton';
import { UserPreferenceContext } from '../contexts/UserPreferenceContext';
import { Cell } from '../styles/styles';

export interface SubQuestDeveloperViewProps {
    quest: SS_MemberQuest;
};

const SubQuestDeveloperView: React.FC<SubQuestDeveloperViewProps> = ({ quest }) => {
    const { buttonProps, isOpen } = useCollapse({ defaultIsOpen: false });
    const { compactDeveloperColumns } = React.useContext(UserPreferenceContext);
    const questColumnValues = useMemo(() => {
        return Object.entries(quest.row).sort((a, b) => sortDeveloperViewHeaders(a[0], b[0])).map((kv) => trimQuotes(JSON.stringify(kv[1])));
    }, [quest.row]);
    const { quests = [], activities = [] } = quest;

    const cellValues = useMemo(() => questColumnValues.map((cellValue, cellNumber) => {
        let value = <>{cellValue}</>;
        if(compactDeveloperColumns) {
            if (cellNumber === 0) {
                const components = cellValue.split('#');
                if (components[1] !== 'All') {
                    value = <>{components[0]}#MID<InlinePopover width={425} content={<>{cellValue}<CopyButton value={cellValue} /></>}/></>;
                }
            } else if (cellNumber === 1) {
                const components = cellValue.split('#');
                const joinedComponents = components.map((c) => c.startsWith('CD_') ? 'CDID' : c.startsWith('QD_') ? 'QDID' : c.startsWith('MQ_') ? 'MQID' : c ).join('#');
                if (cellValue !== joinedComponents) {
                    value = <>{joinedComponents}<InlinePopover width={600} content={<>{cellValue}<CopyButton value={cellValue} /></>}/></>;
                }
            } else if (cellNumber === 4 && cellValue[0] === '[') {
                const json = JSON.parse(cellValue);
                value = <>[array of {json.length}]<InlinePopover width={725} content={<>{<pre>{JSON.stringify(json, undefined, 2)}</pre>}<CopyButton value={cellValue} /></>}/></>;
            } else if ((cellNumber === 6 || cellNumber === 7) && cellValue[0] === '{') {
                const json = JSON.parse(cellValue);
                value = <>[object]<InlinePopover width={725} content={<>{<pre>{JSON.stringify(json, undefined, 2)}</pre>}<CopyButton value={cellValue} /></>}/></>;
            }
        }
        return value;
    }), [questColumnValues, compactDeveloperColumns]);

    return (
        <>
            <Table.Row>
                <Cell $button>
                    <Button variant='tertiary' rounded size='24px' {...buttonProps}>
                        <IconSymbol icon={isOpen ? 'expand_less' : 'expand_more'} size='$md' variant='outlined' />
                    </Button>
                </Cell>
                {cellValues.map((value, cellNumber) => (
                    <Cell key={cellNumber}>{value}</Cell>
                ))}
            </Table.Row>
            {isOpen &&
                <Table.Row>
                    <Table.Cell colSpan={100} style={{maxWidth: '98vw', overflow: 'scroll'}}>
                        <OverflowWrapper>
                            <SubChallengeDeveloperRow record={quest.qd?.row} pkRename='pk.QD' />
                            <SubChallengeDeveloperRow record={quest.mqc?.row} pkRename='pk.MQC' />
                            <SubChallengeDeveloperRow record={quest.mqd?.row} pkRename='pk.MQD' />
                            <SubChallengeDeveloperRow record={quest.mqp?.row} pkRename='pk.MQP' />
                            <SubChallengeDeveloperRow record={quest.mqr?.row} pkRename='pk.MQR' />
                            {quests.length > 0 &&
                                <SubChallengeQuestDeveloperRow quests={quests} />
                            }
                            {activities.length > 0 &&
                                <SubChallengeActivityEventDeveloperRow activities={activities}/>
                            }
                        </OverflowWrapper>
                    </Table.Cell>
                </Table.Row>
            }
        </>
    );
};

const OverflowWrapper = styled.div`
    overflow: scroll;
    max-width: 100%;
    width:100%;
    max-height: 74vh;
    white-space: nowrap;
`;

export default SubQuestDeveloperView;
