import { DataType } from '../constants/challengeTypes';
import {
    KeystoneSearchResultsModel,
    MemberIdType,
    SearchResponse,
    PlanGroupDataModel,
    GroupDataModel
} from '../constants/types';
import {
    MAPSPMapping,
    MAPSGMapping,
    PlanData,
    GroupData,
    SS_MemberProgramRegistration,
    MemberProgramRegistration
} from '../constants/keystoneTypes';
// import { getName } from '../helpers/challengeUtils';
// import { utcToNaiveEndDate, utcToNaiveStartDate } from '../helpers/dateUtils';

// Type definitions for data structures
// Interface for membership data in eligibility responses
interface EligibilityMembership {
    packageBenefitPlanCode?: string;
    hContractId?: string;
    segmentId?: string;
    effectiveDate?: string;         // Date the plan becomes effective
    terminationDate?: string;       // Date the plan terminates
    membershipGroupData?: Array<{
        groupNumber?: string;
        masterGroup?: string;
        effectiveDates?: {
            startDate?: string;     // Group start date
            endDate?: string;       // Group end date
        };
        [key: string]: unknown;
    }>;
    [key: string]: unknown;
}

// Interface for eligibility data structure
interface EligibilityData {
    memberships?: EligibilityMembership[];
    [key: string]: unknown;
}

// Type for dynamic SearchResponse properties
type DynamicProperties = {
    [key: string]: unknown[] | number;
};
// import { FormData } from '../components/MemberSearchForm';
// import { AMAZON_HALO, APPLE_HEALTH, FITBIT, GARMIN, NO_PARTNER } from '../constants/partners';
// import { ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES, VIGOROUS_ACTIVE_MINUTES } from '../constants/activityTypes';
import { KeystoneSearchFormState } from '../store/formStore';
import { executeSearchQuery } from './searchService';
import { getMemberByEnterpriseId, processEimpResult } from './eimpService';
import { fetchEligibilityForMultipleHouseholds } from './hcpEligibilityService';

export interface SearchFlags {
    challengeConsoleLogging: boolean;
    keystoneEligibilityEnabled?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export const processSearch = async (token: string, data: FormData, toast: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, setData: React.Dispatch<React.SetStateAction<any>>) => {
//     let success = true;
//     const errorTitle = `Partial search failed to load for ID: ${data.idValue}`;
//     let errorMessage = 'Some data may have inaccuracies. Please try again. For repeated failures, please report the issue under the "ISSUES" tab.';
//     const requests: Promise<void>[] = [];

//     toast.clean();
//     setData(null);

//     let responseData: SearchResponse = { Count: 0, ScannedCount: 0 } as SearchResponse;

//     const requestData = `idType=${data.idType}&Search_Input=${data.idValue}&${data.dataType.filter((dt : string) => dt.indexOf("MQ") === -1).map((t : string) => `${t}=on`).join('&')}&responseVersion=v2`;

//     requests.push(
//         axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
//             requestData, {
//             headers: {
//                 'Authorization': `Bearer ${token}`,
//                 'Content-Type': 'application/x-www-form-urlencoded'
//             }
//         })
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         .then((response: AxiosResponse<SearchResponse>) => {
//             if (response.data.Count == 0) {
//                 toast.clean();
//                 toast.show({
//                     message: `No records found for ${data.idValue} under ${data.idType} `,
//                     variant: 'error',
//                     autoClose: false
//                 });
//             }
//             else {
//                 const Count = responseData.Count + response.data.Count;
//                 const ScannedCount = responseData.ScannedCount + response.data.ScannedCount;
//                 responseData = {
//                     ...responseData,
//                     ...response.data,
//                     Count,
//                     ScannedCount,
//                 }
//             }
//         })
//         .catch((response: Error | AxiosError) => {
//             success = false;
//             if (axios.isAxiosError(response)) {
//                 errorMessage += ` Error: ${(response as AxiosError).message}.`;
//             }
//             else {
//                 errorMessage += ` Error: ${(response as Error).message}.`;
//             }
//         })
//     );

//     for(const dt of data.dataType.filter((dt : string) => dt.indexOf("MQ") > -1)) {
//         const requestData = `idType=${data.idType}&Search_Input=${data.idValue}&${dt}=on&responseVersion=v2`;

//         requests.push(
//             axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
//                 requestData, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/x-www-form-urlencoded'
//                 }
//             })
//             // eslint-disable-next-line @typescript-eslint/no-explicit-any
//             .then((response: AxiosResponse<SearchResponse>) => {
//                 if (response.data.Count !== 0) {
//                     const Count = responseData.Count + response.data.Count;
//                     const ScannedCount = responseData.ScannedCount + response.data.ScannedCount;
//                     responseData = {
//                         ...responseData,
//                         ...response.data,
//                         Count,
//                         ScannedCount,
//                     }
//                 }
//             })
//             .catch((response: Error | AxiosError) => {
//                 success = false;
//                 if (axios.isAxiosError(response)) {
//                     errorMessage += ` Error: ${(response as AxiosError).message}.`;
//                 }
//                 else {
//                     errorMessage += ` Error: ${(response as Error).message}.`;
//                 }
//             })
//         );
//     };

//     if(data.dataType.indexOf(DataType.AE) > -1 && data.activityTypes.length > 0) {
//         const requestData = `idType=${data.idType}&Search_Input=${data.idValue}&AE=on&activityType=${data.activityTypes.join(',')}&responseVersion=v2`;

//         requests.push(
//             axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
//                 requestData, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/x-www-form-urlencoded'
//                 }
//             })
//             // eslint-disable-next-line @typescript-eslint/no-explicit-any
//             .then((response: AxiosResponse<SearchResponse>) => {
//                 if (response.data.Count !== 0) {
//                     const Count = responseData.Count + response.data.Count;
//                     const ScannedCount = responseData.ScannedCount + response.data.ScannedCount;
//                     responseData = {
//                         ...responseData,
//                         ...response.data,
//                         Count,
//                         ScannedCount,
//                     }
//                 }
//             })
//             .catch((response: Error | AxiosError) => {
//                 success = false;
//                 console.log(response);
//                 if (axios.isAxiosError(response)) {
//                     errorMessage += ` Error: ${(response as AxiosError).message}.`;
//                     console.log('axios error');
//                 }
//                 else {
//                     errorMessage += ` Error: ${(response as Error).message}.`;
//                 }
//             })
//         );
//     };

//     await Promise.all(requests)
//     .then(() => {
//         setData(responseData);
//         setIsLoading(false);
//     })
//     .finally(() => {
//         if(!success) {
//             toast.clean();
//             toast.show({
//                 title: errorTitle,
//                 message: errorMessage,
//                 variant: 'error',
//                 autoClose: false
//             });
//         }
//     });
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processKeystoneSearch = async (
    token: string, 
    data: KeystoneSearchFormState, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any, 
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, 
    setData: React.Dispatch<React.SetStateAction<SearchResponse | null>>,
    searchFlags: SearchFlags = { challengeConsoleLogging: false }
) => {
    let success = true;
    const errorTitle = `Partial search failed to load for ID: ${data.idValue}`;
    const errorMessage = 'Some data may have inaccuracies. Please try again. For repeated failures, please report the issue under the "ISSUES" tab.';
    
    // Check if keystoneEligibilityEnabled flag is present and enabled
    const isKeystoneEligibilityEnabled = searchFlags.keystoneEligibilityEnabled ?? false;
    
    if (searchFlags.challengeConsoleLogging) {
        console.log(`Keystone Eligibility Feature Flag is ${isKeystoneEligibilityEnabled ? 'enabled' : 'disabled'}`);
    }

    toast.clean();
    setData(null);

    let responseData: SearchResponse = { Count: 0, ScannedCount: 0 } as SearchResponse;
    let allHouseholdIds: string[] = [];

    try {
        // Step 0: Query Keystone for MPR records
        if (data.idType === MemberIdType.EID) {
            try {
                if (searchFlags.challengeConsoleLogging) console.log('Step 0: Querying Keystone for MPR records by EID:', data.idValue);
                const mprResult = await executeSearchQuery(token, {
                    idType: MemberIdType.EID,
                    idValue: data.idValue,
                    dataTypes: [DataType.MPR],
                    isDirectSearch: 'true' // Bypass standard ID flow
                });
                
                if (mprResult.success && mprResult.data) {
                    // Merge the MPR data into the response data
                    responseData = mergeSearchResponses(responseData, mprResult.data as SearchResponse);
                    if (searchFlags.challengeConsoleLogging) console.log(`Retrieved ${mprResult.data.MPR?.length || 0} MPR records from Keystone`);
                } else {
                    if (searchFlags.challengeConsoleLogging) console.warn('Failed to get MPR records from Keystone:', mprResult.error || 'Unknown error');
                }
            } catch (error) {
                if (searchFlags.challengeConsoleLogging) console.error('Error in Keystone MPR query:', error);
                // Don't set success to false, as this is just an additional data source
                // and failing to get MPR records should not prevent the rest of the search
            }
        }
        
        // Step 1: Query EIMP by EID - Only if feature flag is enabled
        if (isKeystoneEligibilityEnabled && data.idType === MemberIdType.EID) {
            try {
                if (searchFlags.challengeConsoleLogging) console.log('Step 1: Querying EIMP by EID:', data.idValue);
                const eimpResult = await getMemberByEnterpriseId(token, data.idValue);
                
                if (eimpResult.status === 'success' && eimpResult.data?.memberInfo) {
                    // Process the EIMP result to extract important identifiers
                    const processedMemberInfo = processEimpResult(eimpResult.data.memberInfo);
                    
                    // Store the processed EIMP response in the responseData
                    responseData.EIMP = processedMemberInfo;
                    
                    // Collect household IDs for further queries
                    if (processedMemberInfo.gpsHouseholdIds?.values?.length) {
                        allHouseholdIds = [...processedMemberInfo.gpsHouseholdIds.values];
                        if (searchFlags.challengeConsoleLogging) console.log('Retrieved household IDs from EIMP:', allHouseholdIds);
                    }
                } else {
                    if (searchFlags.challengeConsoleLogging) console.warn('Failed to get member information from EIMP:', eimpResult.message || 'Unknown error');
                    success = false;
                }
            } catch (error) {
                if (searchFlags.challengeConsoleLogging) console.error('Error in EIMP API call:', error);
                success = false;
            }
        }

        // Step 2: Query HCP by each HHID in EIMP - Only if feature flag is enabled
        const planIds: string[] = [];
        const contractIdsMap: Record<string, Array<{ planCode: string, segmentIds: string[] }>> = {}; // Map to store hContractId -> Array of planCode/segmentIds
        // Store full group data instead of just group numbers
        const groupData: Array<{
            groupNumber: string;
            masterGroup?: string;
            startDate?: string;
            endDate?: string;
        }> = [];

        if (isKeystoneEligibilityEnabled && allHouseholdIds.length > 0) {
            try {
                if (searchFlags.challengeConsoleLogging) console.log('Step 2: Querying HCP by household IDs');
                // Fetch eligibility data for all household IDs
                const eligibilityResults = await fetchEligibilityForMultipleHouseholds(
                    token,
                    allHouseholdIds
                );
                
                // Store the eligibility results in the response data
                responseData.Eligibility = eligibilityResults;
                
                // Extract plan and contract information for step 3
                eligibilityResults.forEach(result => {
                    if (result.status === 'success' && result.data?.data?.eligibilities) {
                        // Use type assertion after converting to unknown first to avoid TypeScript errors
                        (result.data.data.eligibilities as unknown as EligibilityData[]).forEach((eligibility: EligibilityData) => {
                            if (eligibility.memberships) {
                                eligibility.memberships.forEach((membership: EligibilityMembership) => {
                                    // Skip the membership object if membershipGroupData is null or empty
                                    if (!membership.membershipGroupData || membership.membershipGroupData.length === 0) {
                                        return; // Skip this membership
                                    }
                                    
                                    // Check if the plan is terminated before 2025-01-01
                                    const cutoffDate = new Date('2025-01-01');
                                    const isPlanTerminated = membership.terminationDate ? 
                                        new Date(membership.terminationDate) < cutoffDate : false;
                                    
                                    // Check if all groups are terminated before 2025-01-01
                                    const areAllGroupsTerminated = membership.membershipGroupData.every(groupData => {
                                        if (!groupData.effectiveDates?.endDate) return false; // If no end date, consider active
                                        return new Date(groupData.effectiveDates.endDate) < cutoffDate;
                                    });
                                    
                                    // Skip the membership only if both the plan AND all groups are terminated
                                    if (isPlanTerminated && areAllGroupsTerminated) {
                                        console.log(`Skipping membership with terminationDate ${membership.terminationDate} and all groups terminated before 1/1/2025`);
                                        return; // Skip this membership
                                    }
                                    
                                    // Store the effectiveDate for logging
                                    const planEffectiveDate = membership.effectiveDate || 'unknown';
                                    
                                    // Extract package benefit plan code for plan IDs
                                    if (membership.packageBenefitPlanCode && !planIds.includes(membership.packageBenefitPlanCode)) {
                                        planIds.push(membership.packageBenefitPlanCode);
                                    }
                                    
                                    // Extract hContractId, packageBenefitPlanCode, and segmentId for MAPSPMapping
                                    if (membership.hContractId && membership.packageBenefitPlanCode) {
                                        if (!contractIdsMap[membership.hContractId]) {
                                            contractIdsMap[membership.hContractId] = [];
                                        }
                                        
                                        // Check if this plan code is already in the array
                                        const existingPlanEntry = contractIdsMap[membership.hContractId].find(
                                            entry => entry.planCode === membership.packageBenefitPlanCode
                                        );
                                        
                                        if (existingPlanEntry) {
                                            // If the entry exists and segmentId is present, add to the array
                                            if (membership.segmentId) {
                                                if (!existingPlanEntry.segmentIds.includes(membership.segmentId)) {
                                                    existingPlanEntry.segmentIds.push(membership.segmentId);
                                                }
                                            } else {
                                                console.log(`Skipping segmentId for plan ${membership.packageBenefitPlanCode} because segmentId is missing`);
                                            }
                                        } else {
                                            // If the entry doesn't exist, create a new one with segmentId if available
                                            if (membership.segmentId) {
                                                contractIdsMap[membership.hContractId].push({
                                                    planCode: membership.packageBenefitPlanCode,
                                                    segmentIds: [membership.segmentId]
                                                });
                                            } else {
                                                // We still create the entry but with empty segmentIds array
                                                contractIdsMap[membership.hContractId].push({
                                                    planCode: membership.packageBenefitPlanCode,
                                                    segmentIds: []
                                                });
                                                console.log(`Creating plan entry with empty segmentIds array for ${membership.packageBenefitPlanCode} because segmentId is missing`);
                                            }
                                        }
                                    }
                                    
                                    // Extract and store full group data in one pass
                                    membership.membershipGroupData.forEach(groupDataItem => {
                                        // We've already filtered out memberships where both plan and all groups are terminated,
                                        // so we can include all remaining groups regardless of their individual termination dates
                                        
                                        // Only process group data if we have both groupNumber and masterGroup
                                        if (groupDataItem.groupNumber && groupDataItem.masterGroup) {
                                            // Store the full group data for later use
                                            const startDate = groupDataItem.effectiveDates?.startDate;
                                            const endDate = groupDataItem.effectiveDates?.endDate;
                                            
                                            // Add to our collection only if not already present
                                            const existingGroup = groupData.find(g => 
                                                g.groupNumber === groupDataItem.groupNumber && 
                                                g.masterGroup === groupDataItem.masterGroup
                                            );
                                            
                                            if (!existingGroup) {
                                                groupData.push({
                                                    groupNumber: groupDataItem.groupNumber,
                                                    masterGroup: groupDataItem.masterGroup,
                                                    startDate,
                                                    endDate
                                                });
                                                
                                                console.log(`Added group data: number=${groupDataItem.groupNumber}, masterGroup=${groupDataItem.masterGroup}, plan effective: ${planEffectiveDate}, terminated: ${membership.terminationDate || 'unknown'}, group start: ${startDate || 'unknown'}, end: ${endDate || 'unknown'}`);
                                            }
                                        } else {
                                            console.log(`Skipping group data because groupNumber or masterGroup is missing: groupNumber=${groupDataItem.groupNumber || 'missing'}, masterGroup=${groupDataItem.masterGroup || 'missing'}`);
                                        }
                                    });
                                });
                            }
                        });
                    }
                });
                
                console.log('Collected plan IDs:', planIds);
                console.log('Collected contract IDs map:', contractIdsMap);
                console.log('Collected group data:', groupData);
                
            } catch (error) {
                console.error('Error fetching eligibility data:', error);
                success = false;
            }
        } else if (isKeystoneEligibilityEnabled) {
            if (searchFlags.challengeConsoleLogging) console.log('Step 2: Skipping HCP eligibility query - no household IDs found');
        }

        // Step 3: Query dynamo for all MAPSPMapping, MAPSGMapping - Only if feature flag is enabled
        const programIds: string[] = [];
        const groupIds: string[] = [];

        if (isKeystoneEligibilityEnabled && (Object.keys(contractIdsMap).length > 0 || groupData.length > 0)) {
            try {
                if (searchFlags.challengeConsoleLogging) console.log('Step 3: Querying dynamo for MAPSPMapping, MAPSGMapping');
            const mappingQueries: Promise<Record<string, unknown>>[] = [];
            
            // Query for MAPSPMapping using hContractId, packageBenefitPlanCode, and segmentId
            Object.entries(contractIdsMap).forEach(([hContractId, planCodeDetails]) => {
                planCodeDetails.forEach(detail => {
                    const { planCode, segmentIds } = detail;
                    
                    // Only query if we have segmentIds - these are required for valid queries
                    if (segmentIds && segmentIds.length > 0) {
                        segmentIds.forEach(segmentId => {
                            // Format as #{hContractId}#{packageBenefitPlanCode}#{segmentId}
                            const mapsPMappingKey = `${hContractId}#${planCode}#${segmentId}`;
                            
                            console.log(`Querying MAPSPMapping with key: ${mapsPMappingKey}`);
                            mappingQueries.push(executeSearchQuery(token, {
                                idType: MemberIdType.EID,
                                idValue: mapsPMappingKey,
                                dataTypes: [DataType.MAPSPMapping],
                                isDirectSearch: 'true' // Bypass standard ID flow
                            }));
                        });
                    } else {
                        console.log(`Skipping MAPSPMapping query for ${hContractId}#${planCode} because segmentId is missing`);
                    }
                });
            });
            
            // Query for MAPSGMapping using groupNumber and masterGroup from our collected group data
            // We need to handle both formats with masterGroup
            console.log('Querying MAPSGMapping with collected group data:', groupData);
            
            // Only query for groups that have a masterGroup - masterGroup is required for valid queries
            groupData.forEach(group => {
                const { groupNumber, masterGroup } = group;
                
                if (masterGroup) {
                    // Format 1: #{groupNumber}#{masterGroup}
                    const mapsGMappingKey1 = `${groupNumber}#${masterGroup}`;
                    console.log(`Querying MAPSGMapping with key format 1: ${mapsGMappingKey1}`);
                    mappingQueries.push(executeSearchQuery(token, {
                        idType: MemberIdType.EID,
                        idValue: mapsGMappingKey1,
                        dataTypes: [DataType.MAPSGMapping],
                        isDirectSearch: 'true' // Bypass standard ID flow
                    }));
                    
                    // Format 2: #{masterGroup}-{groupNumber}#{masterGroup}
                    const mapsGMappingKey2 = `${masterGroup}-${groupNumber}#${masterGroup}`;
                    console.log(`Querying MAPSGMapping with key format 2: ${mapsGMappingKey2}`);
                    mappingQueries.push(executeSearchQuery(token, {
                        idType: MemberIdType.EID,
                        idValue: mapsGMappingKey2,
                        dataTypes: [DataType.MAPSGMapping],
                        isDirectSearch: 'true' // Bypass standard ID flow
                    }));
                } else {
                    console.log(`Skipping MAPSGMapping query for group number ${groupNumber} because masterGroup is missing`);
                }
            });
            
            if (mappingQueries.length > 0) {
                const mappingResults = await Promise.all(mappingQueries);
                
                // Process results to extract program and group IDs
                mappingResults.forEach(result => {
                    // Extract program IDs from MAPSPMapping
                    if (result.data && (result.data as Record<string, unknown>).MAPSPMapping) {
                        ((result.data as Record<string, unknown>).MAPSPMapping as Array<{ programId?: string }>).forEach((mapping: { programId?: string }) => {
                            if (mapping.programId && !programIds.includes(mapping.programId)) {
                                programIds.push(mapping.programId);
                            }
                        });
                    }
                    
                    // Extract group IDs from MAPSGMapping
                    if (result.data && (result.data as Record<string, unknown>).MAPSGMapping) {
                        ((result.data as Record<string, unknown>).MAPSGMapping as Array<{ groupId?: string }>).forEach((mapping: { groupId?: string }) => {
                            if (mapping.groupId && !groupIds.includes(mapping.groupId)) {
                                groupIds.push(mapping.groupId);
                            }
                        });
                    }
                    
                    // Merge results
                    responseData = mergeSearchResponses(responseData, result);
                });
                
                console.log('Collected program IDs:', programIds);
                console.log('Collected group IDs:', groupIds);
            }
        } catch (error) {
            console.error('Error querying for mappings:', error);
            success = false;
        }
        } else if (isKeystoneEligibilityEnabled) {
            if (searchFlags.challengeConsoleLogging) console.log('Step 3: Skipping MAPSPMapping, MAPSGMapping queries - no contractIds or groupData available');
        }

        // Step 4: Query dynamo for all G and P's from step 3 - Only if feature flag is enabled
        if (isKeystoneEligibilityEnabled && (programIds.length > 0 || groupIds.length > 0)) {
            try {
                if (searchFlags.challengeConsoleLogging) console.log('Step 4: Querying dynamo for G and P');
                const finalQueries: Promise<Record<string, unknown>>[] = [];
                
                // Query for programs
                programIds.forEach(programId => {
                    // Format the program ID key as MAPS_<programId> - Lambda will add the P# prefix
                    const formattedProgramId = `MAPS_${programId}`;
                    
                    finalQueries.push(executeSearchQuery(token, {
                        idType: MemberIdType.EID,
                        idValue: formattedProgramId,
                        dataTypes: [DataType.P],
                        isDirectSearch: 'true' // Bypass standard ID flow
                    }));
                });
                
                // Query for groups
                groupIds.forEach(groupId => {
                    // Format the group ID key as P#MAPS_<groupId> - Lambda will add the G# prefix
                    const formattedGroupId = `P#MAPS_${groupId}`;
                    
                    finalQueries.push(executeSearchQuery(token, {
                        idType: MemberIdType.EID,
                        idValue: formattedGroupId,
                        dataTypes: [DataType.G],
                        isDirectSearch: 'true' // Bypass standard ID flow
                    }));
                });
                
                if (finalQueries.length > 0) {
                    const finalResults = await Promise.all(finalQueries);
                    
                    // Merge results
                    finalResults.forEach(result => {
                        responseData = mergeSearchResponses(responseData, result);
                    });
                }
            } catch (error) {
                console.error('Error querying for programs and groups:', error);
                success = false;
            }
        } else if (isKeystoneEligibilityEnabled) {
            if (searchFlags.challengeConsoleLogging) console.log('Step 4: Skipping G and P queries - no program or group IDs found');
        }

        // If no data was found, show a message
        if (responseData.Count === 0) {
            toast.show({
                message: `No records found for ${data.idValue} under ${data.idType}`,
                variant: 'error',
                autoClose: false
            });
        }
        // If partial success, show a warning
        else if (!success) {
            toast.show({
                title: errorTitle,
                message: errorMessage,
                variant: 'warning',
                autoClose: false
            });
        }

        // Return the data
        setData(responseData);
    } catch (error) {
        console.error('Error in keystoneSearch:', error);
        toast.show({
            title: 'Search failed',
            message: `Error processing search: ${error instanceof Error ? error.message : 'Unknown error'}`,
            variant: 'error',
            autoClose: false
        });
    } finally {
        setIsLoading(false);
    }
};

/**
 * Helper function to merge two SearchResponse objects
 */
const mergeSearchResponses = (target: SearchResponse, source: Record<string, unknown>): SearchResponse => {
    // Create a new SearchResponse object with the target's properties
    const result = { ...target };
    
    // Merge all array properties
    Object.keys(source).forEach((key: string) => {
        // Only process if the source value is an array
        if (Array.isArray(source[key])) {
            // Initialize the property as an empty array if it doesn't exist
            if (!result[key as keyof SearchResponse]) {
                (result as DynamicProperties)[key] = [];
            }
            // Merge the arrays
            if (Array.isArray((result as DynamicProperties)[key])) {
                (result as DynamicProperties)[key] = [...((result as DynamicProperties)[key] as unknown[]), ...(source[key] as unknown[])];
            }
        }
    });
    
    // Update count and scanned count
    result.Count = (result.Count || 0) + ((source.Count as number) || 0);
    result.ScannedCount = (result.ScannedCount || 0) + ((source.ScannedCount as number) || 0);
    
    return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processKeystoneResults = (data: SearchResponse | null | undefined, searchFlags: SearchFlags) => {
    // Process eligibility data to create plan-group relationships
    const planGroupData: PlanGroupDataModel[] = [];
    
    if (data?.Eligibility) {
        // Create maps for lookups
        const mapsPMappingMap = new Map();
        const mapsGMappingMap = new Map();
        const planDataMap = new Map();
        const groupDataMap = new Map();
        
        // Organize MAPSPMapping data by its composite key (planId + segmentId)
        if (data.MAPSPMapping) {
            data.MAPSPMapping.forEach((mapping: MAPSPMapping) => {
                if (mapping.pk) {
                    mapsPMappingMap.set(mapping.pk, mapping);
                }
            });
        }
        
        // Organize MAPSGMapping data by its composite key (groupNumber)
        if (data.MAPSGMapping) {
            data.MAPSGMapping.forEach((mapping: MAPSGMapping) => {
                if (mapping.pk) {
                    mapsGMappingMap.set(mapping.pk, mapping);
                }
            });
        }
        
        // Organize P data by id
        if (data.P) {
            data.P.forEach((plan: PlanData) => {
                // Extract id from pk - format is "P#{id}"
                const id = plan.pk?.split('#')[1];
                if (id) {
                    // Set id, startDate, and endDate for compatibility with existing code
                    plan.id = id;
                    plan.startDate = plan.sk; // Effective date
                    plan.endDate = plan.termDate;
                    
                    // Add to map with id as key
                    planDataMap.set(id, plan);
                }
            });
        }
        
        // Organize G data by id
        if (data.G) {
            data.G.forEach((group: GroupData) => {
                // Extract id from pk - format is "G#{id}"
                const id = group.pk?.split('#')[1];
                if (id) {
                    // Set id, startDate, and endDate for compatibility with existing code
                    group.id = id;
                    group.startDate = group.sk; // Effective date
                    group.endDate = group.termDate;
                    
                    // Add to map with id as key
                    groupDataMap.set(id, group);
                }
            });
        }
        
        // Extract all plan-group relationships from eligibility data
        data.Eligibility.forEach((result) => {
            if (result.status === 'success' && result.data?.data?.eligibilities) {
                // Use type assertion after converting to unknown first to avoid TypeScript errors
                (result.data.data.eligibilities as unknown as EligibilityData[]).forEach((eligibility: EligibilityData) => {
                    if (eligibility.memberships) {
                        eligibility.memberships.forEach((membership: EligibilityMembership) => {
                            // Skip the membership object if membershipGroupData is null or empty
                            if (!membership.hContractId || !membership.packageBenefitPlanCode || !membership.membershipGroupData || membership.membershipGroupData.length === 0) {
                                return; // Skip this membership
                            }
                            
                            // Check if the plan is terminated before 2025-01-01
                            const cutoffDate = new Date('2025-01-01');
                            const isPlanTerminated = membership.terminationDate ? 
                                new Date(membership.terminationDate) < cutoffDate : false;
                            
                            // Check if all groups are terminated before 2025-01-01
                            const areAllGroupsTerminated = membership.membershipGroupData.every(groupData => {
                                if (!groupData.effectiveDates?.endDate) return false; // If no end date, consider active
                                return new Date(groupData.effectiveDates.endDate) < cutoffDate;
                            });
                            
                            // Skip the membership only if both the plan AND all groups are terminated
                            if (isPlanTerminated && areAllGroupsTerminated) {
                                if (searchFlags.challengeConsoleLogging) {
                                    console.log(`Skipping membership with terminationDate ${membership.terminationDate} and all groups terminated before 1/1/2025`);
                                }
                                return; // Skip this membership
                            }
                            
                            // Construct the key for MAPSPMapping lookup
                            let planMappingKey = '';
                            if (membership.hContractId && membership.packageBenefitPlanCode && membership.segmentId) {
                                planMappingKey = `MAPSPMapping#${membership.hContractId}#${membership.packageBenefitPlanCode}#${membership.segmentId}`;
                            }
                            
                            // Look up MAPSPMapping
                            const planMappingData: MAPSPMapping | undefined = mapsPMappingMap.get(planMappingKey);
                            
                            // Look up associated P data if planMappingData has planKey
                            let planData: PlanData | undefined = undefined;
                            if (planMappingData && planMappingData.planKey && planMappingData.planKey.pk) {
                                // Extract programId from planKey.pk - format is "P#{programId}"
                                const programId = planMappingData.planKey.pk.split('#')[1];
                                if (programId) {
                                    planData = planDataMap.get(programId);
                                }
                            }
                            
                            // Create groups data with associated MAPSGMapping and G data
                            const groups: GroupDataModel[] = [];
                            
                            membership.membershipGroupData.forEach((groupDataItem) => {
                                if (!groupDataItem.groupNumber) return; // Skip if no group number
                                
                                // The groupNumber alone isn't enough to create the exact MAPSGMapping key
                                // since the format is MAPSGMapping#{groupNumber}#{additionalId}
                                // So we need to search for all MAPSGMapping keys that start with this groupNumber
                                let groupMappingData: MAPSGMapping | undefined = undefined;
                                
                                // Find the first MAPSGMapping that matches this groupNumber
                                // This is an approximation - in a more robust implementation, we'd need additional logic
                                // to determine which specific MAPSGMapping is the correct one for this group
                                const groupNumberPrefix = `MAPSGMapping#${groupDataItem.groupNumber}#`;
                                for (const [key, mapping] of mapsGMappingMap.entries()) {
                                    if (key.startsWith(groupNumberPrefix)) {
                                        groupMappingData = mapping;
                                        break;
                                    }
                                }
                                
                                // Look up associated G data if groupMappingData has groupKey
                                let groupData: GroupData | undefined = undefined;
                                if (groupMappingData && groupMappingData.groupKey && groupMappingData.groupKey.pk) {
                                    // Extract groupId from groupKey.pk - format is "G#{groupId}"
                                    const groupId = groupMappingData.groupKey.pk.split('#')[1];
                                    if (groupId) {
                                        groupData = groupDataMap.get(groupId);
                                    }
                                }
                                
                                groups.push({
                                    groupNumber: groupDataItem.groupNumber || '',
                                    masterGroup: groupDataItem.masterGroup || '',
                                    startDate: groupDataItem.effectiveDates?.startDate || '',
                                    endDate: groupDataItem.effectiveDates?.endDate || '',
                                    groupMappingData,
                                    groupData
                                });
                            });
                            
                            // Only add to planGroupData if there are groups
                            if (groups.length > 0) {
                                planGroupData.push({
                                    contractId: membership.hContractId || '',
                                    pbp: membership.packageBenefitPlanCode || '',
                                    segmentId: membership.segmentId || '',
                                    effectiveDate: membership.effectiveDate || '',
                                    terminationDate: membership.terminationDate || '',
                                    planMappingData,
                                    planData,
                                    groups
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    const memberProgramRegistrations: SS_MemberProgramRegistration[] = data?.MPR?.map((row: MemberProgramRegistration) => {
        const skProgramId = row.sk.split('#')[1];
        const pkEid = row.pk.split('#')[1];
        return ({
            ...row,
            row: row,
            pkEid,
            skProgramId,
        });
    }) ?? [];

    const keystoneSearchResults: KeystoneSearchResultsModel = {
        memberProgramRegistrations,
        eligibilityData: data?.Eligibility || [],
        planGroupData
    };

    if(searchFlags.challengeConsoleLogging) {
        console.log('Processed plan-group data:', planGroupData);
        console.log('Full search response:', data);
    }

    return keystoneSearchResults;
};

// export const processSearchResultsSorted = (data: SearchResponse, memberSearchResults: MemberSearchResultsModel, searchFlags: SearchFlags) => {
//     searchFlags.challengeConsoleLogging && console.log(data);

//     if(data.MD && data.MD.length > 0) {
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         memberSearchResults.deviceTypePairDates = data.MD.map((md: any) => ({
//                 dataType: md.sk.split('#')[0],
//                 partner: md.sk.split('#')[1],
//                 firstPairDate: md.devicePairDateTimes && md.devicePairDateTimes.length > 0 ? md.devicePairDateTimes[0] : '-',
//                 latestPairDate: md.devicePairDateTimes && md.devicePairDateTimes.length > 0 ? md.devicePairDateTimes.at(-1) : '-',
//             })
//         );
//     }

//     if(data.M && data.M.length > 0) {
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         const member = data.M.find((m: any) => m.sk === 'MemberInformation');
//         if(member !== undefined) {
//             memberSearchResults.memberIds.naviId = member['pk'].substring(member['pk'].indexOf('#') + 1);
//             memberSearchResults.memberIds.enterpriseId = member['enterpriseId'];
//             memberSearchResults.memberIds.rallyId = member['rallyId'];
//             memberSearchResults.memberIds.hsidUsername = member['hsidUsername'];
//             memberSearchResults.memberIds.hsidUuid = member['hsidUuid'];
//             memberSearchResults.demographics.firstName = member['firstName'];
//             memberSearchResults.demographics.lastName = member['lastName'];
//             memberSearchResults.demographics.dob = member['birthDate'];
//             memberSearchResults.demographics.registeredDate = member['registerDate'];
//             memberSearchResults.demographics.gender = member['gender'];
//             memberSearchResults.demographics.policyId = member['policyNumber'];
//             memberSearchResults.demographics.planName = member['planName'];
//             memberSearchResults.demographics.lob = member['lineOfBusiness'];
//             memberSearchResults.demographics.programStartDate = member['programStartDate'];
//             memberSearchResults.demographics.utcOffset = member['utcOffset'];
//         }
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         const mmd = data.M.find((m: any) => m.sk === 'MMD');
//         if(mmd !== undefined) {
//             memberSearchResults.additionalData.lastSyncDate = mmd['lastActivitySyncDateTime'];
//             if(!memberSearchResults.additionalData.lastActivityReceived) memberSearchResults.additionalData.lastActivityReceived = {};
//             memberSearchResults.additionalData.lastActivityReceived.date = mmd['latestActivityDataDateTime'];
//             memberSearchResults.additionalData.assignedUpToDate = mmd['assignedUpToDate'];
//         }
//     }

//     if(data.RR && data.RR.length > 0) {
//         for(const rr of data.RR) {
//             memberSearchResults.memberIds.hasRR = true;
//             if(memberSearchResults.memberIds.hasRRSK === undefined || memberSearchResults.memberIds.hasRRSK === false) {
//                 memberSearchResults.memberIds.hasRRSK = rr['sk'] === 'SK';
//             }
//         }
//     }

//     if(data.ER && data.ER.length > 0) {
//         memberSearchResults.memberIds.hasER = true;
//     }

//     memberSearchResults.plans = data.MP?.map((row: MemberPlan) => {
//         const skEndDateTime = row.sk.split('#')[0];
//         return ({
//             ...row,
//             row: row,
//             skEndDateTime: skEndDateTime,
//             adjustedSkEndDateTime: utcToNaiveEndDate(skEndDateTime),
//             adjustedEffectiveDateTime: utcToNaiveStartDate(row.effectiveDateTime),
//             campaigns: [],
//         });
//     }) ?? [];

//     memberSearchResults.plans.map((plan) => {
//         plan.campaigns = data.CD?.filter((c: Campaign) => plan.adjustedEffectiveDateTime >= c.startDateTime && plan.adjustedSkEndDateTime <= c.sk.split('#')[0]).map((c: Campaign) => ({
//             ...c,
//             row: c,
//             skEndDateTime: c.sk.split('#')[0],
//             id: c.sk.split('#')[1],
//             quests: [],
//         })) ?? [];
//     });

//     data.MCF?.map((mcf: MemberCampaignFocus) => {
//         const plan = memberSearchResults.plans.find((p) => mcf.startDateTime >= p.adjustedEffectiveDateTime && mcf.startDateTime <= p.adjustedSkEndDateTime);
//         if(!plan) {
//             searchFlags.challengeConsoleLogging && console.log(`Progress ${mcf.pk}:${mcf.sk} with startDateTime ${mcf.startDateTime} does not belong to any plan.`);
//             return;
//         }
//         const campaign = plan.campaigns.find((c) => c.pk === mcf.campaignDefinitionId.pk && c.sk === mcf.campaignDefinitionId.sk);
//         if(!campaign) {
//             searchFlags.challengeConsoleLogging && console.log(`Progress ${mcf.pk}:${mcf.sk} with startDateTime ${mcf.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
//             return;
//         }
//         campaign.mcf = {
//             ...mcf,
//             row: mcf,
//         };
//     });

//     data.MCP?.map((mcp: MemberCampaignProgress) => {
//         const plan = memberSearchResults.plans.find((p) => mcp.startDateTime >= p.adjustedEffectiveDateTime && mcp.startDateTime <= p.adjustedSkEndDateTime);
//         if(!plan) {
//             searchFlags.challengeConsoleLogging && console.log(`Progress ${mcp.pk}:${mcp.sk} with startDateTime ${mcp.startDateTime} does not belong to any plan.`);
//             return;
//         }
//         const campaign = plan.campaigns.find((c) => c.pk === mcp.campaignDefinitionId.pk && c.sk === mcp.campaignDefinitionId.sk);
//         if(!campaign) {
//             searchFlags.challengeConsoleLogging && console.log(`Progress ${mcp.pk}:${mcp.sk} with startDateTime ${mcp.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
//             return;
//         }
//         campaign.mcp = {
//             ...mcp,
//             row: mcp,
//         };
//     });

//     const topLevelMemberCampaigns = memberSearchResults.plans.flatMap((p) => p.campaigns);

//     data.QD?.map((q: Quest) => {
//         topLevelMemberCampaigns.filter((c) => c.questDefinitionId.pk === q.pk && c.questDefinitionId.sk === q.sk)
//             .map((c) => c.qd = {
//                 ...q,
//                 row: q,
//         });
//     });

//     const leftOverQuests: MemberQuest[] = [];

//     data.MQ?.map((mq: MemberQuest) => {
//         const plan = memberSearchResults.plans.find((p) => mq.startDateTime >= p.adjustedEffectiveDateTime && mq.startDateTime <= p.adjustedSkEndDateTime);
//         if(!plan) {
//             leftOverQuests.push(mq);
//             searchFlags.challengeConsoleLogging && console.log(`Progress ${mq.pk}:${mq.sk} with startDateTime ${mq.startDateTime} does not belong to any plan.`);
//             return;
//         }
//         const campaign = plan.campaigns.find((c) => c.questDefinitionId.pk === mq.questId.pk && c.questDefinitionId.sk === mq.questId.sk);
//         if(!campaign) {
//             leftOverQuests.push(mq);
//             searchFlags.challengeConsoleLogging && console.log(`Progress ${mq.pk}:${mq.sk} with startDateTime ${mq.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
//             return;
//         }
//         const activities = mq.calculationActivity === 'QuestCompletion' ? [] :
//             filterActivitiesByTypeAndDateRange(data.AE, mq.calculationActivity, mq.startDateTime, mq.sk.split('#')[0], searchFlags).map((ae: ActivityEvent) => ({
//                 ...ae,
//                 row: ae,
//             })) ?? [];

//         campaign.quests.push({
//             ...mq,
//             row: mq,
//             skEndDateTime: mq.sk.split('#')[0],
//             quests: [],
//             activities,
//         });
//         campaign.quests.sort((a: SS_MemberQuest, b: SS_MemberQuest) => a.startDateTime > b.startDateTime ? -1 : 1);
//     });

//     const topLevelMemberQuests = topLevelMemberCampaigns.flatMap((c) => c.quests);
//     processChildQuests(data, topLevelMemberQuests, leftOverQuests, searchFlags);

//     searchFlags.challengeConsoleLogging && console.log(leftOverQuests);
//     searchFlags.challengeConsoleLogging && console.log(memberSearchResults.plans);

//     const skDates = topLevelMemberQuests
//         .filter((q) => q.mqp !== undefined && q.mqp.completionDateTime !== undefined && q.mqp.completionDateTime !== null)
//         .sort((a, b) => (a.mqp?.completionDateTime as string) > (b.mqp?.completionDateTime as string) ? -1 : 1);
//     if(skDates.length > 0) {
//         memberSearchResults.additionalData.lastCompletedQuest = {
//             sk: skDates[0].mqp?.sk as string,
//             date: skDates[0].mqp?.completionDateTime as string,
//             name: getName(skDates[0].rallyActivityId ?? ''),
//         };
//     }

//     return memberSearchResults;
// }

// const processChildQuests = (data: SearchResponse, memberQuests: SS_MemberQuest[], leftOverQuests: MemberQuest[], searchFlags: SearchFlags) => {
//     memberQuests.map((mq: SS_MemberQuest) => {
//         const mqId = mq.sk.split('#')[1];
//         const mqc = data.MQC?.find((mqc: MemberQuestCalculation) => mqId === mqc.sk.split('#')[2]);
//         if(mqc !== undefined) {
//             mq.mqc = {
//                 ...mqc,
//                 row: mqc,
//             };
//         }

//         const mqd = data.MQD?.find((mqd: MemberQuestDisplay) => mqId === mqd.sk.split('#')[2]);
//         if(mqd !== undefined) {
//             mq.mqd = {
//                 ...mqd,
//                 row: mqd,
//             };
//         }

//         const mqp = data.MQP?.find((mqp: MemberQuestProgress) => mqId === mqp.sk.split('#')[2]);
//         if(mqp !== undefined) {
//                 mq.mqp = {
//                 ...mqp,
//                 row: mqp,
//             };
//         }

//         const mqr = data.MQR?.find((mqr: MemberQuestReward) => mqId === mqr.sk);
//         if(mqr !== undefined) {
//             mq.mqr = {
//                 ...mqr,
//                 row: mqr,
//             };
//         }

//         const qd = data.QD?.find((q: Quest) => mq.questId.sk === q.sk);
//         if(qd !== undefined) {
//             mq.qd = {
//                 ...qd,
//                 row: qd,
//             };
//         }

//         const childQuests: MemberQuest[] = [];
//         let i = 0;
//         while(i < leftOverQuests.length) {
//             if(leftOverQuests[i].parentMemberQuestSk !== null && leftOverQuests[i].parentMemberQuestSk?.endDateTime + '#' + leftOverQuests[i].parentMemberQuestSk?.memberQuestId === mq.sk) {
//                 childQuests.push(leftOverQuests[i]);
//                 leftOverQuests.splice(i, 1);
//             } else {
//                 i++;
//             }
//         }

//         mq.activities = mq.calculationActivity === 'QuestCompletion' ? [] :
//             filterActivitiesByTypeAndDateRange(data.AE, mq.calculationActivity, mq.startDateTime, mq.sk.split('#')[0], searchFlags).map((ae: ActivityEvent) => ({
//                 ...ae,
//                 row: ae,
//             })) ?? [];

//         childQuests.map((cq: MemberQuest) => {
//             mq.quests.push({
//                 ...cq,
//                 row: cq,
//                 skEndDateTime: cq.sk.split('#')[0],
//                 quests: [],
//                 activities: [],
//             });
//         });
//         mq.quests.sort((a: SS_MemberQuest, b: SS_MemberQuest) => a.startDateTime > b.startDateTime ? -1 : 1);
//     });

//     const childrenMemberQuests = memberQuests.flatMap((q) => q.quests);
//     if(childrenMemberQuests.length > 0) {
//         processChildQuests(data, childrenMemberQuests, leftOverQuests, searchFlags);
//     }
// }

// export const filterActivitiesByTypeAndDateRange = <T extends { pk: string, sk: string, partner?: string }>(activityEvents: T[], activityType: string, startDate: string, endDate: string, searchFlags: SearchFlags): T[] => {
//     if(!searchFlags.filterOutIncorrectActiveMinutes) {
//         return activityEvents?.filter((ae) => ae.pk.split('#')[2].indexOf(activityType) >= 0 && ae.sk >= startDate && ae.sk <= endDate) ?? [];
//     }
//     return activityEvents?.filter((ae) => ae.sk >= startDate && ae.sk <= endDate && ae.pk.split('#')[2].indexOf(activityType) >= 0 &&
//         (
//             ![VIGOROUS_ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES, ACTIVE_MINUTES].includes(activityType)
//             || ([FITBIT, GARMIN, AMAZON_HALO].includes(ae?.partner ?? NO_PARTNER) && [VIGOROUS_ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
//             || ([APPLE_HEALTH].includes(ae?.partner ?? NO_PARTNER) && [MODERATE_ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
//             || (![FITBIT, GARMIN, APPLE_HEALTH, AMAZON_HALO].includes(ae?.partner ?? NO_PARTNER) && [ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
//         )
//     ) ?? [];
// }
