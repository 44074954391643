import React from 'react';
import styled from 'styled-components';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Layout } from '@abyss/web/ui/Layout';
import { Card } from '@abyss/web/ui/Card';
import { Table } from '@abyss/web/ui/Table';
import { Heading } from '@abyss/web/ui/Heading';
import { KeystoneSearchResultsModel, PlanGroupDataModel } from '../constants/types';
import MemberProgramRegistrationTable from './MemberProgramRegistrationTable';
import InlinePopover from './InlinePopover';
import { Cell, HeaderCell } from '../styles/styles';

export interface KeystoneSearchResultsProps {
    data: KeystoneSearchResultsModel;
};

// Component for the eligibility status card
const EligibilityStatusCard: React.FC<{ isEligible: boolean }> = ({ isEligible }) => {
    return (
        <SizeWrapper>
            <Card size='small'>
                <Table.Container title='Eligibility Status'>
                    <Table.TableBody>
                        <Table.Row>
                            <HeaderCell $header>Eligible for Rewards Today</HeaderCell>
                            <Cell>
                                {isEligible ? 
                                    <GreenWordWrapper>Yes</GreenWordWrapper> : 
                                    <RedWordWrapper>No</RedWordWrapper>
                                }
                            </Cell>
                        </Table.Row>
                    </Table.TableBody>
                </Table.Container>
            </Card>
        </SizeWrapper>
    );
};

// Row component for the expected eligibility table
interface ExpectedEligibilityRowProps {
    planGroup: {
        contractId: string;
        pbp: string;
        segmentId: string;
        effectiveDate: string;
        terminationDate: string;
        group: {
            groupNumber: string;
            masterGroup?: string;
            startDate?: string;
            endDate?: string;
        };
        isPlanEligible: boolean;
        isGroupEligible: boolean;
    };
}

const ExpectedEligibilityRow: React.FC<ExpectedEligibilityRowProps> = ({ planGroup }) => {
    const { 
        contractId, 
        pbp, 
        segmentId, 
        effectiveDate, 
        terminationDate, 
        group,
        isPlanEligible,
        isGroupEligible
    } = planGroup;
    
    const groupNumber = group.groupNumber || '-';
    const masterGroup = group.masterGroup || '-';
    const rewardsEligible = isPlanEligible || isGroupEligible;

    const popoverContent = (
        <div>
            <div>Plan Eligible: {isPlanEligible ? <GreenWordWrapper>Yes</GreenWordWrapper> : <RedWordWrapper>No</RedWordWrapper>}</div>
            <div>Group Eligible: {isGroupEligible ? <GreenWordWrapper>Yes</GreenWordWrapper> : <RedWordWrapper>No</RedWordWrapper>}</div>
        </div>
    );

    return (
        <Table.Row>
            <Cell>{contractId}</Cell>
            <Cell>{pbp}</Cell>
            <Cell>{segmentId}</Cell>
            <Cell>{groupNumber}</Cell>
            <Cell>{masterGroup}</Cell>
            <Cell>{effectiveDate}</Cell>
            <Cell>{terminationDate}</Cell>
            <Cell>
                {rewardsEligible ? 
                    <GreenWordWrapper>Yes <InlinePopover content={popoverContent} /></GreenWordWrapper> : 
                    <RedWordWrapper>No <InlinePopover content={popoverContent} /></RedWordWrapper>
                }
            </Cell>
        </Table.Row>
    );
};

// Main expected eligibility table component
interface ExpectedEligibilityTableProps {
    planGroupData: PlanGroupDataModel[];
}

const ExpectedEligibilityTable: React.FC<ExpectedEligibilityTableProps> = ({ planGroupData }) => {
    // Check if a date is between start and end dates
    const isDateInRange = (startDate: string | undefined, endDate: string | undefined) => {
        if (!startDate || !endDate) return false;
        const today = new Date();
        return new Date(startDate) <= today && today <= new Date(endDate);
    };

    // Transform the planGroupData into row data with eligibility information
    const rowData = planGroupData.flatMap(plan => {
        return plan.groups.map(group => {
            // Check date eligibility (between effective and termination dates)
            const isPlanDateEligible = isDateInRange(plan.effectiveDate, plan.terminationDate);
            const isGroupDateEligible = isDateInRange(group.startDate, group.endDate);
            
            // Check rewards eligibility from P and G records
            // A plan is eligible if:
            // 1. It's within date range AND
            // 2. Either the planData.rewardsEligible is true OR we don't have planData.rewardsEligible (null/undefined)
            const isPlanRewardsEligible = plan.planData?.rewardsEligible ?? null;
            const isPlanEligible = isPlanDateEligible && (isPlanRewardsEligible === true || isPlanRewardsEligible === null);
            
            // Same logic for groups
            const isGroupRewardsEligible = group.groupData?.rewardsEligible ?? null;
            const isGroupEligible = isGroupDateEligible && (isGroupRewardsEligible === true || isGroupRewardsEligible === null);
            
            return {
                contractId: plan.contractId,
                pbp: plan.pbp,
                segmentId: plan.segmentId,
                effectiveDate: plan.effectiveDate,
                terminationDate: plan.terminationDate,
                group,
                isPlanEligible,
                isGroupEligible
            };
        });
    });

    return (
        <Layout.Stack space={4} alignLayout='left'>
            <Heading display='h5'>Member&apos;s Expected Eligibility</Heading>
            <Table.Container title="Expected Eligibility Table">
                <Table.TableHeader>
                    <Table.Row>
                        <HeaderCell>Contract ID</HeaderCell>
                        <HeaderCell>PBP</HeaderCell>
                        <HeaderCell>Segment ID</HeaderCell>
                        <HeaderCell>Group Number</HeaderCell>
                        <HeaderCell>Master Group</HeaderCell>
                        <HeaderCell>Effective Date</HeaderCell>
                        <HeaderCell>Termination Date</HeaderCell>
                        <HeaderCell>Rewards Eligible</HeaderCell>
                    </Table.Row>
                </Table.TableHeader>
                <Table.TableBody>
                    {rowData.length > 0 ? (
                        rowData.map((planGroup, index) => (
                            <ExpectedEligibilityRow 
                                key={index} 
                                planGroup={planGroup} 
                            />
                        ))
                    ) : (
                        <Table.Row>
                            <Cell colSpan={8}>No eligibility data available</Cell>
                        </Table.Row>
                    )}
                </Table.TableBody>
            </Table.Container>
        </Layout.Stack>
    );
};

const KeystoneSearchResults: React.FC<KeystoneSearchResultsProps> = ({ data }) => {
    const ldClient = useLDClient();
    
    // Two separate flags for different features
    const isKeystoneSearchEnabled = ldClient?.variation('enable_ss_keystone_search', false) as boolean;
    const isKeystoneEligibilityEnabled = ldClient?.variation('release_ss_keystone_eligibility', false) as boolean;
    
    const { memberProgramRegistrations, planGroupData = [] } = data;

    // Determine if the member is eligible for rewards today
    const isEligibleToday = () => {
        // Check if there are any active MPR records
        if (!memberProgramRegistrations || memberProgramRegistrations.length === 0) {
            return false;
        }
        
        const today = new Date();
        const isDateInRange = (startDate: string | undefined, endDate: string | undefined) => {
            if (!startDate) return false;
            const start = new Date(startDate);
            
            // If no end date is provided, assume it's still active
            if (!endDate) return start <= today;
            
            const end = new Date(endDate);
            return start <= today && today <= end;
        };

        // Check each MPR record to see if any are currently active
        for (const mpr of memberProgramRegistrations) {
            // Check if the rewards dates include today
            if (isDateInRange(mpr.rewardsEffectiveDate, mpr.rewardsTermDate)) {
                return true;
            }
        }
        
        // No active MPR records found
        return false;
    };

    return (
        //TODO: add 'grow' back when we add more content
        <Layout.Stack space={8} alignLayout='left' /*grow*/>
            {/* Check if basic Keystone search is enabled */}
            {!isKeystoneSearchEnabled ? (
                <div>
                    <Card size='small'>
                        <Layout.Stack space={4} alignLayout='center'>
                            <Heading display='h5'>Keystone Search Feature is Not Yet Available</Heading>
                            <p>The Keystone search functionality is currently disabled in this environment.</p>
                        </Layout.Stack>
                    </Card>
                </div>
            ) : (
                <React.Fragment>
                    {/* Part 1: Eligibility Status Card */}
                    <EligibilityStatusCard isEligible={isEligibleToday()} />
                    <Layout.Space />

                    {/* Only show eligibility component if enhanced eligibility feature flag is enabled */}
                    {isKeystoneEligibilityEnabled && (
                        <React.Fragment>
                            {/* Part 2: Expected Eligibility Table */}
                            <ExpectedEligibilityTable 
                                planGroupData={planGroupData}
                            />
                            <Layout.Space />
                        </React.Fragment>
                    )}
            
                    {/* Part 3: MPR Table with Header - Always show when basic search is enabled */}
                    {memberProgramRegistrations && memberProgramRegistrations.length > 0 && (
                        <Layout.Stack space={4} alignLayout='left'>
                            <Heading display='h5'>Member&apos;s Currently Configured Rewards Eligibility</Heading>
                            <MemberProgramRegistrationTable registrations={memberProgramRegistrations} />
                        </Layout.Stack>
                    )}
                </React.Fragment>
            )}
        </Layout.Stack>
    );
};

const SizeWrapper = styled.div`
    width: 300px;
`;

const GreenWordWrapper = styled.div`
    color: green;
    display: inline;
`;

const RedWordWrapper = styled.div`
    color: red;
    display: inline;
`;

export default KeystoneSearchResults;
