import React from 'react';
import styled from 'styled-components';
import { Table } from '@abyss/web/ui/Table';
import { Button } from '@abyss/web/ui/Button';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { useUserRoleContext } from '../auth/UserRoleContext';
import { ChallengeView } from '../constants/challengeTypes';
import SubChallengeDeveloperView from './SubChallengeDeveloperView';
import ChallengeDetailView from './ChallengeDetailView';
import { Cell } from '../styles/styles';

export interface ChallengeViewRowProps {
    challenge: ChallengeView;
    naviId: string;
};

const ChallengeViewRow: React.FC<ChallengeViewRowProps> = ({ challenge, naviId }) => {
    const engineerViewCollapse = useCollapse({ defaultIsOpen: false });
    const csViewCollapse = useCollapse({ defaultIsOpen: false });
    const { isEngineer } = useUserRoleContext();
    const { name, description, type, progress, progressPercent, active, startFocusDate, lastCompletionDate, campaign } = challenge;

    return (
        <>
            <Table.Row>
                <Cell $button $selected={csViewCollapse.isOpen || engineerViewCollapse.isOpen}>
                    {isEngineer &&
                        <TableButtonWrapper>
                            <Button variant='tertiary' rounded size='24px' {...engineerViewCollapse.buttonProps} disabled={csViewCollapse.isOpen}>
                                <IconSymbol icon={engineerViewCollapse.isOpen ? 'expand_less' : 'table_view'} size='$md' variant='outlined' />
                            </Button>
                        </TableButtonWrapper>
                    }
                </Cell>
                {[name, description, type, progress, progressPercent, active, startFocusDate, lastCompletionDate].map((text, i) =>
                    <Cell $selected={csViewCollapse.isOpen || engineerViewCollapse.isOpen} key={i}>{text}</Cell>
                )}
                <Cell $button $selected={csViewCollapse.isOpen || engineerViewCollapse.isOpen}>
                    <TableButtonWrapper>
                        <Button variant='ghost' rounded size='24px' {...csViewCollapse.buttonProps} disabled={engineerViewCollapse.isOpen}>
                            <IconSymbol icon={csViewCollapse.isOpen ? 'expand_less' : 'expand_more'} size='$md' variant='outlined' />
                        </Button>
                    </TableButtonWrapper>
                </Cell>
            </Table.Row>
            {csViewCollapse.isOpen &&
                <ChallengeDetailView campaign={campaign} naviId={naviId}/>
            }
            {isEngineer && engineerViewCollapse.isOpen &&
                <SubChallengeDeveloperView campaign={campaign}/>
            }
        </>
    );
};

const TableButtonWrapper = styled.div`
    line-height: 0px;
`;

export default ChallengeViewRow;
