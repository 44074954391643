import React, { useCallback, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import styled from 'styled-components';
import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Table } from '@abyss/web/ui/Table';
import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { SS_Campaign, SS_MemberQuest } from '../constants/challengeTypes';
import { isoDateTimeToIsoDate, isoDateTimeToIsoDateTime } from '../helpers/utils';
import { floatToPercent } from '../helpers/intUtils';
import InlinePopover from './InlinePopover';
import SubChallengeView from './SubChallengeView';
import SubChallengeActivityView from './SubChallengeActivityView';
import { Cell, TopMarginBlockWrapper } from '../styles/styles';
import { processRecalc } from '../services/recalc';

export interface ChallengeDetailViewRowProps {
    campaign: SS_Campaign;
    quest: SS_MemberQuest;
    naviId: string;
};

interface RowData {
    rewardedQuest: SS_MemberQuest | undefined;
    rewardedDate: string | undefined;
    steps: number | undefined;
    activeMin: number | undefined;
    vigMin: number | undefined;
    modMin: number | undefined;
    hasStepsAndGoal: number | undefined;
    achievedSteps: boolean;
    hasActiveMinAndGoal: number | undefined;
    achievedActiveMin: boolean;
    hasVigMinAndGoal: number | undefined;
    achievedVigMin: boolean;
    hasModMinAndGoal: number | undefined;
    achievedModMin: boolean;
    isMarkedNotAchieved: boolean;
};

interface FormData {
    naviId: string;
    date: string;
};

const ChallengeDetailViewRow: React.FC<ChallengeDetailViewRowProps> = ({ quest, campaign, naviId }) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();

    const { buttonProps, isOpen } = useCollapse({ defaultIsOpen: false });

    const { rewardedQuest, rewardedDate, steps, activeMin, vigMin, modMin, hasStepsAndGoal, achievedSteps, hasActiveMinAndGoal, achievedActiveMin, hasVigMinAndGoal, achievedVigMin, hasModMinAndGoal, achievedModMin, isMarkedNotAchieved } = useMemo(() => {
        let rewardedQuest = quest as SS_MemberQuest | undefined;
        let rewardedDate = rewardedQuest?.mqr?.rallyPostDateTime;
        if (rewardedDate === undefined || rewardedDate === null) {
            rewardedQuest = quest.quests.find(q => q.mqr?.rallyPostDateTime != undefined && q.mqr?.rallyPostDateTime != null);
            rewardedDate = rewardedQuest?.mqr?.rallyPostDateTime;
        }
        const stepQuest = quest.quests.find(q => q.calculationActivity === 'Steps');
        let steps = stepQuest?.activities.find(a => a.pk.split('#')[2] === 'Steps')?.amount;
        if(steps !== undefined && steps !== null) {
            steps = Math.floor(steps);
        };
        const activeMinQuest = quest.quests.find(q => q.calculationActivity === 'ActiveMinutes');
        const activeMin = activeMinQuest?.activities.find(a => a.pk.split('#')[2] === 'ActiveMinutes')?.amount;
        const vigMin = activeMinQuest?.activities.find(a => a.pk.split('#')[2] === 'VigorousActiveMinutes')?.amount;
        const modMin = activeMinQuest?.activities.find(a => a.pk.split('#')[2] === 'ModerateActiveMinutes')?.amount;

        const rowData: RowData = {
            rewardedQuest: rewardedQuest,
            rewardedDate: rewardedDate,
            steps: steps,
            activeMin: activeMin,
            vigMin: vigMin,
            modMin: modMin,
            hasStepsAndGoal: steps && stepQuest?.qd?.goal,
            achievedSteps: steps && stepQuest?.qd?.goal ? steps >= stepQuest.qd?.goal : false,
            hasActiveMinAndGoal: activeMin && activeMinQuest?.qd?.goal,
            achievedActiveMin: activeMin && activeMinQuest?.qd?.goal ? activeMin >= activeMinQuest.qd?.goal : false,
            hasVigMinAndGoal: vigMin && activeMinQuest?.qd?.goal,
            achievedVigMin: vigMin && activeMinQuest?.qd?.goal ? vigMin + (modMin ?? 0) >= activeMinQuest.qd?.goal : false,
            hasModMinAndGoal: modMin && activeMinQuest?.qd?.goal,
            achievedModMin: modMin && activeMinQuest?.qd?.goal ? modMin + (vigMin ?? 0) >= activeMinQuest.qd?.goal : false,
            isMarkedNotAchieved: false, // temp value, will be set below
        };
        rowData.isMarkedNotAchieved = rewardedDate === undefined && (rowData.achievedSteps || rowData.achievedActiveMin || rowData.achievedVigMin || rowData.achievedModMin);

        return rowData;
    }, [quest]);

    const [isRecalcuating, setIsRecalcuating] = React.useState(false);

    const defaultValues = {
        naviId: naviId,
        date: quest.startDateTime,
    };

    const form = useForm({
        defaultValues: defaultValues,
    });

    const isDailyDefault = campaign.campaignType === 'DailyDefault';

    const onRecalc = useCallback(async (data : FormData, e : Event) => {
        setIsRecalcuating(true);
        e.preventDefault();

        const dates = [isoDateTimeToIsoDate(data.date) ?? '-'];
        console.log(dates);

        if(!token) {
            toast.show({
                message: 'User not authenticated',
                variant: 'error',
                autoClose: false
            });
            setIsRecalcuating(false);
            return;
        }
        await processRecalc(token, data.naviId, dates, toast, setIsRecalcuating);
    }, [token, toast]);

    return (
        <>
            <Table.Row>
                {isDailyDefault && <>
                    <Cell $selected={isOpen}>{isoDateTimeToIsoDate(quest.startDateTime)}</Cell>
                </>}
                {!isDailyDefault && <>
                    <Cell $selected={isOpen}>{isoDateTimeToIsoDate(quest.startDateTime)}</Cell>
                    <Cell $selected={isOpen}>{isoDateTimeToIsoDate(quest.skEndDateTime)}</Cell>
                </>}
                {!isDailyDefault &&
                    <Cell $selected={isOpen}>{floatToPercent(quest.mqp?.progressPercent ?? 0)}</Cell>
                }
                {isDailyDefault && <>
                    <Cell $selected={isOpen}>{hasStepsAndGoal ? (isMarkedNotAchieved && achievedSteps ? <RedBackgroundWrapper>{achievedSteps}</RedBackgroundWrapper> : achievedSteps ? <GreenBackgroundWrapper>{steps}</GreenBackgroundWrapper> : steps) : '-'}</Cell>
                    <Cell $selected={isOpen}>{hasActiveMinAndGoal ? (isMarkedNotAchieved && activeMin ? <RedBackgroundWrapper>{activeMin}</RedBackgroundWrapper> : achievedActiveMin ? <GreenBackgroundWrapper>{activeMin}</GreenBackgroundWrapper> : activeMin) : '-'}</Cell>
                    <Cell $selected={isOpen}>{hasVigMinAndGoal ? (isMarkedNotAchieved && achievedVigMin ? <RedWordWrapper>{vigMin}</RedWordWrapper> : achievedVigMin ? <GreenWordWrapper>{vigMin}</GreenWordWrapper> : vigMin) : '-'}</Cell>
                    <Cell $selected={isOpen}>{hasModMinAndGoal ? (isMarkedNotAchieved && achievedModMin ? <RedWordWrapper>{modMin}</RedWordWrapper> : achievedModMin ? <GreenWordWrapper>{modMin}</GreenWordWrapper> : modMin) : '-'}</Cell>
                </>}
                <Cell $semibold $selected={isOpen}>{
                    quest.mqp?.completionDateTime ? isoDateTimeToIsoDate(quest.mqp?.completionDateTime) : <>
                        {'-'}
                        <InlinePopover icon='warning' displayed={isDailyDefault && isMarkedNotAchieved} content={
                            <>
                                It seems the member completed the quest but we failed to calculate it. Recalculate this date?
                                <FormProvider state={form} onSubmit={onRecalc}
                                onError={undefined}>
                                    <TopMarginBlockWrapper>
                                        <Button
                                            type='submit'
                                            size='24px'
                                            isLoading={isRecalcuating}
                                            ariaLoadingLabel='Recalculating'
                                        >
                                            Recalculate
                                        </Button>
                                    </TopMarginBlockWrapper>
                                </FormProvider>
                            </>
                        }/>
                    </>}
                </Cell>
                <Cell $selected={isOpen}>{
                    isoDateTimeToIsoDateTime(rewardedDate ?? '-')}
                    <InlinePopover width={500} displayed={rewardedDate ? true : false} content={
                        <>
                            <div><strong>Rally Transaction ID:</strong> {rewardedQuest?.mqr?.rallyTransactionId}</div>
                            <div><strong>Rally Post Date:</strong> {rewardedQuest?.mqr?.rallyPostDateTime}</div>
                            <div><strong>Reported Completion Date:</strong> {rewardedQuest?.mqr?.reportedCompletionDateTime}</div>
                        </>
                    }/>
                </Cell>
                <Cell $selected={isOpen}>{isoDateTimeToIsoDateTime(quest.mqp?.lastCalculationDateTime)}</Cell>
                <Cell $button $selected={isOpen}>
                    {(quest.quests.length > 0 || quest.activities.length > 0) &&
                        <TableButtonWrapper>
                            <Button variant='ghost' rounded size='24px' {...buttonProps}>
                                <IconSymbol icon={isOpen ? 'expand_less' : 'expand_more'} size='$md' variant='outlined' />
                            </Button>
                        </TableButtonWrapper>
                    }
                </Cell>
            </Table.Row>
            {isOpen && quest.quests.length > 0 &&
                <Table.Row>
                    <Table.Cell colSpan={100}>
                        <SubChallengeView quest={quest} campaign={campaign}/>
                    </Table.Cell>
                </Table.Row>
            }
            {isOpen && quest.activities.length > 0 &&
                <Table.Row key={quest.questId + 'a'}>
                    <Table.Cell colSpan={100}>
                        <SubChallengeActivityView quest={quest} />
                    </Table.Cell>
                </Table.Row>
            }
        </>
    );
};

const TableButtonWrapper = styled.div`
    line-height: 0px;
`;

const GreenWordWrapper = styled.div`
    color: var(--abyss-colors-statusDvz1);
`;

const GreenBackgroundWrapper = styled.div`
    display: inline;
    background-color: var(--abyss-colors-statusDvz1);
    color: white;
    padding: 3px 3px;
`;

const RedWordWrapper = styled.div`
    color: var(--abyss-colors-error1);
`;

const RedBackgroundWrapper = styled.div`
    display: inline;
    background-color: var(--abyss-colors-error1);
    color: white;
    padding: 3px 3px;
`;

export default ChallengeDetailViewRow;
