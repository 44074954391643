import { FC } from 'react';
import styled from 'styled-components';
import { Modal } from '@abyss/web/ui/Modal';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { iconColor } from '../helpers/utils';

export interface HelpModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const HelpModal: FC<HelpModalProps> = ({ isOpen, setIsOpen }) => {

    return (
        <Modal title='Help' isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Section>
                <ul>
                    <li>
                        <MarginWrapper>
                            <IconSymbol icon='info' size='$sm' variant='outlined' color={iconColor('info')} />
                        </MarginWrapper>
                        <span>View more information about a particular item</span>
                    </li>
                    <li>
                        <MarginWrapper>
                            <IconSymbol icon='help_outline' size='$sm' variant='outlined' color={iconColor('help_outline')} />
                        </MarginWrapper>
                        <span>Provides explanation or help on an item</span>
                    </li>
                    <li>
                        <MarginWrapper>
                            <IconSymbol icon='new_releases' size='$sm' variant='outlined' color={iconColor('new_releases')} />
                        </MarginWrapper>
                        <span>Newly released feature</span>
                    </li>
                    <li>
                        <MarginWrapper>
                            <IconSymbol icon='update' size='$sm' variant='outlined' color={iconColor('update')} />
                        </MarginWrapper>
                        <span>Recently updated for fixed item</span>
                    </li>
                    <li>
                        <MarginWrapper>
                            <IconSymbol icon='check_circle' size='$sm' variant='outlined' color={iconColor('check_circle')} />
                        </MarginWrapper>
                        <span>Indicates a successful action or operation</span>
                    </li>
                    <li>
                        <MarginWrapper>
                            <IconSymbol icon='warning' size='$sm' variant='outlined' color={iconColor('warning')} />
                        </MarginWrapper>
                        <span>Warning about something such as a data issue like a missing reward</span>
                    </li>
                    <li>
                        <MarginWrapper>
                            <IconSymbol icon='error_outline' size='$sm' variant='outlined' color={iconColor('error_outline')} />
                        </MarginWrapper>
                        <span>Provides more context about an error, such as a recalculation error</span>
                    </li>
                </ul>
            </Modal.Section>
            <Modal.Section>
                More information to come in future enhancements
            </Modal.Section>
        </Modal>
    );
};

const MarginWrapper = styled.span`
    margin-right: 10px;
`;

export default HelpModal;
