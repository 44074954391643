import axios, { AxiosError } from 'axios';
import { MemberIdType } from '../constants/types';
import { DataType } from '../constants/challengeTypes';
import { ActivityType } from '../constants/activityTypes';

/**
 * Interface for search query parameters
 */
export interface SearchQueryParams {
  idType: MemberIdType;
  idValue: string;
  dataTypes?: DataType[];
  activityTypes?: ActivityType[];
  responseVersion?: string;
  isDirectSearch?: string; // Flag to bypass standard ID flow in searchQueryLambda
}

/**
 * Execute a search query against the search API
 * @param token Authentication token
 * @param params Search query parameters
 * @returns Promise with the search results
 */
export const executeSearchQuery = async (token: string, params: SearchQueryParams) => {
  const { idType, idValue, dataTypes = [], activityTypes = [], responseVersion = 'v2', isDirectSearch } = params;
  
  // Build the request data string
  let requestData = `idType=${idType}&Search_Input=${idValue}`;
  
  // Add data types if provided
  if (dataTypes.length > 0) {
    requestData += `&${dataTypes.map(t => `${t}=on`).join('&')}`;
  }
  
  // Add activity types if provided
  if (activityTypes.length > 0) {
    requestData += `&activityType=${activityTypes.join(',')}`;
  }
  
  // Add response version
  requestData += `&responseVersion=${responseVersion}`;
  
  // Add direct search flag if provided
  if (isDirectSearch) {
    requestData += `&isDirectSearch=${isDirectSearch}`;
  }
  
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
      requestData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    
    return {
      success: true,
      data: response.data,
      error: null
    };
  } catch (error) {
    let errorMessage = 'Unknown error occurred';
    
    if (axios.isAxiosError(error)) {
      errorMessage = (error as AxiosError).message;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    
    return {
      success: false,
      data: null,
      error: errorMessage
    };
  }
};

/**
 * Execute a search query for a specific data type
 * @param token Authentication token
 * @param idType ID type to search by
 * @param idValue ID value to search for
 * @param dataType Specific data type to search for
 * @returns Promise with the search results
 */
export const searchByDataType = async (token: string, idType: MemberIdType, idValue: string, dataType: DataType) => {
  return executeSearchQuery(token, {
    idType,
    idValue,
    dataTypes: [dataType]
  });
};

/**
 * Execute a search query for activity events
 * @param token Authentication token
 * @param idType ID type to search by
 * @param idValue ID value to search for
 * @param activityTypes Activity types to search for
 * @returns Promise with the search results
 */
export const searchActivityEvents = async (
  token: string,
  idType: MemberIdType,
  idValue: string,
  activityTypes: ActivityType[]
) => {
  return executeSearchQuery(token, {
    idType,
    idValue,
    dataTypes: [DataType.AE],
    activityTypes
  });
};

/**
 * Execute a search query for member data
 * @param token Authentication token
 * @param idType ID type to search by
 * @param idValue ID value to search for
 * @param excludeMQTypes Whether to exclude MQ data types
 * @returns Promise with the search results
 */
export const searchMemberData = async (
  token: string,
  idType: MemberIdType,
  idValue: string,
  excludeMQTypes = true
) => {
  // Get all data types
  const allDataTypes = Object.values(DataType);
  
  // Filter out MQ types if requested
  const dataTypes = excludeMQTypes
  ? allDataTypes.filter(dt => !dt.includes('MQ'))
    : allDataTypes;
  
  return executeSearchQuery(token, {
    idType,
    idValue,
    dataTypes
  });
};

/**
 * Execute a search query for quest data
 * @param token Authentication token
 * @param idType ID type to search by
 * @param idValue ID value to search for
 * @returns Promise with the search results for all MQ data types
 */
export const searchQuestData = async (token: string, idType: MemberIdType, idValue: string) => {
  // Get all MQ data types
  const mqDataTypes = Object.values(DataType).filter(dt => dt.includes('MQ'));
  
  // Execute a search for each MQ data type
  const searchPromises = mqDataTypes.map(dataType => 
    searchByDataType(token, idType, idValue, dataType)
  );
  
  // Wait for all searches to complete
  const results = await Promise.all(searchPromises);
  
  // Combine the results
  const combinedResult = {
    success: results.every(r => r.success),
    data: results.reduce<Record<string, unknown>>((acc, r) => {
      if (r.success && r.data) {
        // Merge the data
        return {
          ...acc,
          ...r.data,
          Count: (acc?.Count || 0) + (r.data.Count || 0),
          ScannedCount: (acc?.ScannedCount || 0) + (r.data.ScannedCount || 0)
        };
      }
      return acc;
    }, { Count: 0, ScannedCount: 0 }),
    errors: results.filter(r => !r.success).map(r => r.error)
  };
  
  return combinedResult;
};
