import React, { FC } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Modal } from '@abyss/web/ui/Modal';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { DefaultTab } from '../constants/types';
import { UserPreferenceContext } from '../contexts/UserPreferenceContext';

export interface UserPreferenceModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const UserPreferenceModal: FC<UserPreferenceModalProps> = ({ isOpen, setIsOpen }) => {
    const { compactDeveloperColumns, toggleCompactDeveloperColumns, defaultTab, setDefaultTab } = React.useContext(UserPreferenceContext);
    const ldClient = useLDClient();
    const enableDefaultTabPreference = ldClient?.variation('enable_ss_default_tab_preference', true) as boolean;

    const defaultTabOptions = [
        { value: DefaultTab.MemberSearch, label: 'Member Search' },
        { value: DefaultTab.RawData, label: 'Raw Data Search' },
        { value: DefaultTab.KeystoneSearch, label: 'Keystone Search' }
    ];

    return (
        <Modal title='User Preferences' isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Section>
                <ToggleSwitch isChecked={compactDeveloperColumns} onChange={toggleCompactDeveloperColumns} label="Compact Developer View Columns" />
                {enableDefaultTabPreference && (
                    <SelectInput
                        label='Default Tab'
                        value={defaultTab}
                        // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
                        onChange={(value: string | number | Function, selectedOption: Record<string, any>) => {
                            if (typeof value === 'number') {
                                setDefaultTab(value as DefaultTab);
                            }
                        }}
                        options={defaultTabOptions}
                        width='200px'
                    />
                )}
            </Modal.Section>
        </Modal>
    );
};

export default UserPreferenceModal;
