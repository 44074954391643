import { create } from 'zustand';
import { ActivityType } from '../constants/activityTypes';
import { DataType } from '../constants/challengeTypes';
import { MemberIdType } from '../constants/types';

// Define the MemberSearchForm state interface
export interface MemberSearchFormState {
  idValue: string;
  idType: MemberIdType;
  dataType: DataType[];
  activityTypes: ActivityType[];
  releaseHsidSearch?: boolean;
}

// Define the RawDataSearchForm state interface
interface RawDataSearchFormState {
  idValue: string;
  idType: MemberIdType;
  // TODO: Add date fields back to the store once we resolve the infinite update loop issue
  // startDate?: Date;
  // endDate?: Date;
  dataType: DataType[];
  activityType?: ActivityType | null;
  // TODO: Add date fields back to the store once we resolve the infinite update loop issue
  // dates?: {
  //   from: string;
  //   to: string;
  // };
}

// Define the KeystoneSearchForm state interface
export interface KeystoneSearchFormState {
  idValue: string;
  idType: MemberIdType;
  dataType: DataType[];
}

// Define the store state interface
interface FormStoreState {
  memberSearchForm: MemberSearchFormState | null;
  rawDataSearchForm: RawDataSearchFormState | null;
  keystoneSearchForm: KeystoneSearchFormState | null;
  
  // Actions
  setMemberSearchForm: (data: MemberSearchFormState) => void;
  updateMemberSearchForm: (data: Partial<MemberSearchFormState>) => void;
  
  setRawDataSearchForm: (data: RawDataSearchFormState) => void;
  updateRawDataSearchForm: (data: Partial<RawDataSearchFormState>) => void;
  
  setKeystoneSearchForm: (data: KeystoneSearchFormState) => void;
  updateKeystoneSearchForm: (data: Partial<KeystoneSearchFormState>) => void;
}

// Create the store
export const useFormStore = create<FormStoreState>()((set) => ({
  memberSearchForm: null,
  rawDataSearchForm: null,
  keystoneSearchForm: null,
  
  // Member Search Form actions
  setMemberSearchForm: (data) => set({ memberSearchForm: data }),
  updateMemberSearchForm: (data) => 
    set((state) => ({ 
      memberSearchForm: state.memberSearchForm 
        ? { ...state.memberSearchForm, ...data } 
        : data as MemberSearchFormState 
    })),
  
  // Raw Data Search Form actions
  setRawDataSearchForm: (data) => set({ rawDataSearchForm: data }),
  updateRawDataSearchForm: (data) => 
    set((state) => ({ 
      rawDataSearchForm: state.rawDataSearchForm 
        ? { ...state.rawDataSearchForm, ...data } 
        : data as RawDataSearchFormState 
    })),
    
  // Keystone Search Form actions
  setKeystoneSearchForm: (data) => set({ keystoneSearchForm: data }),
  updateKeystoneSearchForm: (data) => 
    set((state) => ({ 
      keystoneSearchForm: state.keystoneSearchForm 
        ? { ...state.keystoneSearchForm, ...data } 
        : data as KeystoneSearchFormState 
    })),
}));
