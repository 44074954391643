import { ChangeEvent, useCallback, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useToast } from '@abyss/web/hooks/useToast';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useForm } from '@abyss/web/hooks/useForm';
import { ActivityType } from '../constants/activityTypes';
import { DataType } from '../constants/challengeTypes';
import { MemberIdType } from '../constants/types';
import { processSearch } from '../services/naviSearch';
import { autoIdType } from '../helpers/utils';
import { TopMarginWrapper } from '../styles/styles';
import { useFormStore, MemberSearchFormState } from '../store/formStore';


export interface MemberSearchFormProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setData: (data: any) => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const MemberSearchForm: React.FC<MemberSearchFormProps> = ({ setData, isLoading, setIsLoading }) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();
    const ldClient = useLDClient();
    const releaseHsidSearch = ldClient?.variation('release_ss_hsid_member_search', false) as boolean;
    
    const { memberSearchForm, setMemberSearchForm, updateMemberSearchForm } = useFormStore();
    
    const defaultSearchValues = {
        idValue: '',
        idType: MemberIdType.RALLY_ID,
        dataType: [DataType.M, DataType.RR, DataType.ER, DataType.MMD, DataType.MD, DataType.MP, DataType.SD, DataType.CD, DataType.QD, DataType.MCF, DataType.MCP, DataType.MQ, DataType.MQD, DataType.MQC, DataType.MQP, DataType.MQR, DataType.AE],
        activityTypes: [ActivityType.STEPS, ActivityType.ACTIVE_MINUTES, ActivityType.VIGOROUS_ACTIVE_MINUTES, ActivityType.MODERATE_ACTIVE_MINUTES, ActivityType.TIME_IN_BED],
        releaseHsidSearch: releaseHsidSearch, // Pass the Launch Darkly flag to the form
    };

    const form = useForm({
        defaultValues: memberSearchForm || defaultSearchValues,
    });
    
    useEffect(() => {
        if (!memberSearchForm) {
            setMemberSearchForm(defaultSearchValues);
        } else if (memberSearchForm.releaseHsidSearch !== releaseHsidSearch) {
            // Update the releaseHsidSearch flag if it changes
            updateMemberSearchForm({ releaseHsidSearch });
        }
    }, [memberSearchForm, setMemberSearchForm, releaseHsidSearch, updateMemberSearchForm, defaultSearchValues]);
    
    // Use a custom onChange handler for the autoIdType function
    const handleAutoIdType = (value: string) => {
        autoIdType(value, form);
        // Get the current form values after autoIdType has potentially updated them
        const currentValues = form.getValues();
        // Update the store with the current ID type
        updateMemberSearchForm({ idType: currentValues.idType });
    };
    
    const onSearch = useCallback(async (data : MemberSearchFormState, e : Event) => {
        setIsLoading(true);
        e.preventDefault();

        setMemberSearchForm(data);

        if(!token) {
            toast.show({
                message: 'User not authenticated',
                variant: 'error',
                autoClose: false
            });
            setIsLoading(false);
            return;
        }
        processSearch(token, data, toast, setIsLoading, setData);
    }, [token, toast, setData, setIsLoading, setMemberSearchForm]);

    return (
        <FormProvider state={form} onSubmit={onSearch} onError={undefined}>
            <Layout.Group alignItems='top'>
                    <TextInput
                        label='Member ID'
                        placeholder='Member ID'
                        model='idValue'
                        isClearable
                        onClear={() => updateMemberSearchForm({ idValue: '' })}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value;
                            updateMemberSearchForm({ idValue: value });
                            handleAutoIdType(value);
                        }}
                        validators={{ required: true }}
                        width='400px'
                    />
                    <SelectInput
                        label='ID Type'
                        model='idType'
                        validators={{ required: true }}
                        width='150px'
                        options={[
                            { label: 'EnterpriseID', value: MemberIdType.EID },
                            { label: MemberIdType.RALLY_ID, value: MemberIdType.RALLY_ID },
                            ...(releaseHsidSearch ? [{ label: 'HSID', value: MemberIdType.HSID }] : []),
                            { label: MemberIdType.NAVI_ID, value: MemberIdType.NAVI_ID },
                        ]}
                        // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
                        onChange={(value: string | number | Function, selectedOption: Record<string, any>) => {
                            if (typeof value === 'string' && Object.values(MemberIdType).includes(value as MemberIdType)) {
                                updateMemberSearchForm({ idType: value as MemberIdType });
                            }
                        }}
                    />
            </Layout.Group>
            <TopMarginWrapper>
                <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Searching' >
                    Search
                </Button>
            </TopMarginWrapper>
        </FormProvider>
    );
};

export default MemberSearchForm;
