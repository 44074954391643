import { FC } from 'react';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import TableResults from './TableResults';
import { DataType } from '../constants/challengeTypes';
import { SearchResults, SearchResult } from './RawDataSearchForm';

interface RawDataResultsProps {
    results: SearchResults;
}

const RawDataResults: FC<RawDataResultsProps> = ({ results }) => {
    // Arrays for different data types
    const QDArr: SearchResult[] = [];
    const MArr: SearchResult[] = [];
    const RRArr: SearchResult[] = [];
    const MDArr: SearchResult[] = [];
    const MPArr: SearchResult[] = [];
    const MMDArr: SearchResult[] = [];
    const ERArr: SearchResult[] = [];
    const MQArr: SearchResult[] = [];
    const MQCArr: SearchResult[] = [];
    const MQDArr: SearchResult[] = [];
    const MQPArr: SearchResult[] = [];
    const MQRArr: SearchResult[] = [];
    const SDArr: SearchResult[] = [];
    const CDArr: SearchResult[] = [];
    const MCFArr: SearchResult[] = [];
    const MCPArr: SearchResult[] = [];
    const DEArr: SearchResult[] = [];
    const ARArr: SearchResult[] = [];
    const GTArr: SearchResult[] = [];

    // Sort data into appropriate arrays
    const response = results.data;
    if (response && response.length > 0) {

        response.forEach((row: SearchResult) => {
            const dtString = row.pk?.substring(0, row.pk.indexOf('#'));
            if (!dtString) return;

            if (dtString === DataType.QD) {
                QDArr.push(row);
            } else if (dtString === DataType.M && row.sk === 'MemberInformation') {
                MArr.push(row);
            } else if (dtString === DataType.RR) {
                RRArr.push(row);
            } else if (dtString === DataType.MD) {
                MDArr.push(row);
            } else if (dtString === DataType.MP) {
                MPArr.push(row);
            } else if (dtString === DataType.MMD || (dtString === DataType.M && row.sk === DataType.MMD)) {
                MMDArr.push(row);
            } else if (dtString === DataType.ER) {
                ERArr.push(row);
            } else if (dtString === DataType.MQ) {
                MQArr.push(row);
            } else if (dtString === DataType.MQC) {
                MQCArr.push(row);
            } else if (dtString === DataType.MQD) {
                MQDArr.push(row);
            } else if (dtString === DataType.MQP) {
                MQPArr.push(row);
            } else if (dtString === DataType.MQR) {
                MQRArr.push(row);
            } else if (dtString === DataType.SD) {
                SDArr.push(row);
            } else if (dtString === DataType.CD) {
                CDArr.push(row);
            } else if (dtString === DataType.MCF) {
                MCFArr.push(row);
            } else if (dtString === DataType.MCP) {
                MCPArr.push(row);
            } else if (dtString === DataType.DE) {
                DEArr.push(row);
            } else if (dtString === DataType.AR) {
                ARArr.push(row);
            } else if (dtString === DataType.GT) {
                GTArr.push(row);
            }
        });
    }

    return (
        <ThemeProvider>
            {MArr.length > 0 && <TableResults props={MArr} currType={DataType.M} />}
            {RRArr.length > 0 && <TableResults props={RRArr} currType={DataType.RR} />}
            {ERArr.length > 0 && <TableResults props={ERArr} currType={DataType.ER} />}
            {MMDArr.length > 0 && <TableResults props={MMDArr} currType={DataType.MMD} />}
            {MDArr.length > 0 && <TableResults props={MDArr} currType={DataType.MD} />}
            {MPArr.length > 0 && <TableResults props={MPArr} currType={DataType.MP} />}
            {SDArr.length > 0 && <TableResults props={SDArr} currType={DataType.SD} />}
            {CDArr.length > 0 && <TableResults props={CDArr} currType={DataType.CD} />}
            {MCFArr.length > 0 && <TableResults props={MCFArr} currType={DataType.MCF} />}
            {MCPArr.length > 0 && <TableResults props={MCPArr} currType={DataType.MCP} />}
            {QDArr.length > 0 && <TableResults props={QDArr} currType={DataType.QD} />}
            {MQArr.length > 0 && <TableResults props={MQArr} currType={DataType.MQ} />}
            {MQCArr.length > 0 && <TableResults props={MQCArr} currType={DataType.MQC} />}
            {MQPArr.length > 0 && <TableResults props={MQPArr} currType={DataType.MQP} />}
            {MQDArr.length > 0 && <TableResults props={MQDArr} currType={DataType.MQD} />}
            {MQRArr.length > 0 && <TableResults props={MQRArr} currType={DataType.MQR} />}
            {DEArr.length > 0 && <TableResults props={DEArr} currType={DataType.DE} />}
            {ARArr.length > 0 && <TableResults props={ARArr} currType={DataType.AR} />}
            {GTArr.length > 0 && <TableResults props={GTArr} currType={DataType.GT} />}
        </ThemeProvider>
    );
};

export default RawDataResults;
