import React, { useMemo } from 'react';
import { Table } from '@abyss/web/ui/Table';
import { SS_MemberQuest } from '../constants/challengeTypes';
import { sortDeveloperViewHeaders } from '../helpers/challengeUtils';
import SubQuestDeveloperView from './SubQuestDeveloperView';
import { HeaderCell, HeaderRow, TableWrapper } from '../styles/styles';

export interface SubChallengeQuestDeveloperRowProps {
    quests: SS_MemberQuest[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyValue = [string, any];
type QuestRow = KeyValue[];

const SubChallengeQuestDeveloperRow: React.FC<SubChallengeQuestDeveloperRowProps> = ({ quests }) => {
    const entries: QuestRow[] = useMemo(() => {
        return quests.map((quest) =>
            Object.entries(quest.row)
            .sort((a, b) => sortDeveloperViewHeaders(a[0], b[0]))
        );
    }, [quests]);

    if(entries.length > 0) {
        entries[0][0][0] = 'pk.MQ';
    }

    return (
        <TableWrapper>
            <Table.Container title="Quest Developer Data">
                <Table.TableHeader>
                    <HeaderRow>
                        <HeaderCell></HeaderCell>
                        {entries[0].map((kv, i) => (
                            <HeaderCell key={i}>{kv[0]}</HeaderCell>
                        ))}
                    </HeaderRow>
                </Table.TableHeader>
                <Table.TableBody>
                    {quests.map((q, i) => (
                        <SubQuestDeveloperView quest={q} key={i}/>
                    ))}
                </Table.TableBody>
            </Table.Container>
        </TableWrapper>
    );
};

export default SubChallengeQuestDeveloperRow;
