import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { RouterProvider } from '@abyss/web/ui/RouterProvider';
import Login from './pages/Login';
import UserRoleProvider, { useUserRoleContext } from './auth/UserRoleContext';
import { UserPreferenceProvider } from './contexts/UserPreferenceContext';
import { router } from './router';

function RawApp() {
    const { isAuthorized, authorized } = useUserRoleContext();
    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            isAuthorized(auth.user);
        }
    }, [auth.isAuthenticated, auth.user]);

    //For development
    const authToggle = JSON.parse(process.env.REACT_APP_AUTH_TOGGLE || 'false');
    if (!authToggle) {
        return (
            <RouterProvider router={router}>
                <div></div>
            </RouterProvider>
        );
    }

    switch (auth.activeNavigator) {
        case 'signinSilent':
            return <div>Signing you in...</div>;
        case 'signoutRedirect':
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return (
            <Layout.Stack alignLayout="center">
                <p>Failed to authenticate. Error: {auth.error.message}</p>
                <Button onClick={() => { auth.removeUser(); window.location.href='/' }}>Go to Login</Button>
            </Layout.Stack>
        );
    }

    if (auth.isAuthenticated) {
        if (authorized) {
            return (
               <RouterProvider router={router}>
                   <div></div>
               </RouterProvider>
            );
        } else {
            return <p>Not authorized.</p>;
        }
    }
    return <Login />;
}

const App = () => (
    <UserRoleProvider>
        <UserPreferenceProvider>
            <RawApp />
        </UserPreferenceProvider>
    </UserRoleProvider>
);
export default App;
