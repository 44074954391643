import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { Table } from '@abyss/web/ui/Table';
import { SS_Campaign } from '../constants/challengeTypes';
import { isoDateTimeToReadableDate } from '../helpers/utils';
import { getLastCalculationDate, getProgressCount } from '../helpers/challengeUtils';
import ChallengeDetailViewRow from './ChallengeDetailViewRow';
import { Cell, HeaderCell, HeaderRow } from '../styles/styles';

export interface ChallengeDetailViewProps {
    campaign: SS_Campaign;
    naviId: string;
};

const ChallengeDetailView: React.FC<ChallengeDetailViewProps> = ({ campaign, naviId }) => {
    const { quests = [] } = campaign;
    const isDailyDefault = campaign.campaignType === 'DailyDefault';

    return (
        <Table.Row>
            <Table.Cell colSpan={10} style={{width: '100%'}}>
                <Heading textAlign='left' offset={5} color='$interactive2'>Challenge Details</Heading>
                <Table.Container title="Challenge Details">
                    <Table.TableHeader>
                        <HeaderRow $transparent>
                            <HeaderCell>Start Date</HeaderCell>
                            <HeaderCell>Last Calculated Date</HeaderCell>
                            <HeaderCell>Completed Count</HeaderCell>
                            <HeaderCell>Rally Activity Id</HeaderCell>
                        </HeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                        <Table.Row>
                            <Cell>{isoDateTimeToReadableDate(campaign.mcp?.startDateTime)}</Cell>
                            <Cell>{getLastCalculationDate(campaign)}</Cell>
                            <Cell>{getProgressCount(campaign)}</Cell>
                            <Cell>{campaign.rallyActivityId}</Cell>
                        </Table.Row>
                    </Table.TableBody>
                </Table.Container>
                <hr/>
                <Table.Container title="Challenge Progress">
                    <Table.TableHeader>
                        <HeaderRow $transparent>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell>Quest Type</HeaderCell>
                            <HeaderCell>Goal</HeaderCell>
                            <HeaderCell>Duration</HeaderCell>
                            <HeaderCell>Sub challenge count</HeaderCell>
                        </HeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                        <Table.Row>
                            <Cell>{campaign.qd?.name}</Cell>
                            <Cell>{campaign.qd?.calculationActivity}</Cell>
                            <Cell>{campaign.qd?.goal}</Cell>
                            <Cell>{campaign.qd?.duration}</Cell>
                            <Cell>{campaign.qd?.calculationRequirements?.dependentMemberQuestProgressIds.length ?? 0}</Cell>
                        </Table.Row>
                    </Table.TableBody>
                </Table.Container>
                <hr/>
                <Table.Container title="Challenge Progress">
                    <Table.TableHeader>
                        <HeaderRow>
                            {isDailyDefault && <>
                                <HeaderCell>Date</HeaderCell>
                                <HeaderCell>Steps</HeaderCell>
                                <HeaderCell>Active Min</HeaderCell>
                                <HeaderCell>Vig Min</HeaderCell>
                                <HeaderCell>Mod Min</HeaderCell>
                            </>}
                            {!isDailyDefault && <>
                                <HeaderCell>Start Date</HeaderCell>
                                <HeaderCell>End Date</HeaderCell>
                                <HeaderCell>Progress</HeaderCell>
                            </>}
                            <HeaderCell>Completed Date</HeaderCell>
                            <HeaderCell>Rewarded Date</HeaderCell>
                            <HeaderCell>Last Calculated Date</HeaderCell>
                            <HeaderCell></HeaderCell>
                        </HeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                        {quests.length > 0 ? quests.map((q1, i) => (
                            <ChallengeDetailViewRow quest={q1} campaign={campaign} naviId={naviId} key={i}/>
                        )) :
                            <Table.Row>
                                <Cell colSpan={7}>No sub challenge data available</Cell>
                            </Table.Row>
                        }
                    </Table.TableBody>
                </Table.Container>
            </Table.Cell>
        </Table.Row>
    );
};

export default ChallengeDetailView;
