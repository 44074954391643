import axios from 'axios';
import { FC, ChangeEvent, useState, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Layout } from '@abyss/web/ui/Layout';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { DateInputRange } from '@abyss/web/ui/DateInputRange';
import { CheckboxTree } from '@abyss/web/ui/CheckboxTree';
import { Button } from '@abyss/web/ui/Button';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ActivityType } from '../constants/activityTypes';
import { DataType } from '../constants/challengeTypes';
import { MemberIdType } from '../constants/types';
import { autoIdType } from '../helpers/utils';
import { TopMarginWrapper } from '../styles/styles';
import { formatDateToMMDDYYYY } from '../helpers/dateUtils';
import { useFormStore } from '../store/formStore';

// export default function RawDataSearchForm() {
export interface RawDataFormState {
    idValue: string;
    idType: MemberIdType;
    startDate?: Date;
    endDate?: Date;
    dataType: DataType[];
    activityType?: ActivityType | null;
}

export interface SearchResult extends Record<string, string | object> {
    pk: string;
    sk: string;
}

export interface SearchResults {
    data: SearchResult[];
}

interface RawDataSearchFormProps {
    setResults?: (results: SearchResults) => void;
}

const RawDataSearchForm: FC<RawDataSearchFormProps> = ({ setResults }) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();
    
    // Get form state from Zustand store
    const { rawDataSearchForm, setRawDataSearchForm, updateRawDataSearchForm } = useFormStore();

    const defaultSearchValues = {
        idType: MemberIdType.RALLY_ID,
        idValue: '',
        dataType: [DataType.M, DataType.RR, DataType.MD, DataType.MP, DataType.MMD, DataType.MQ, DataType.MQC, DataType.MQD, DataType.MQP, DataType.MQR, DataType.MCF, DataType.MCP],
        activityType: null,
        dates: {
            from: '',
            to: ''
        }
    };

    // Initialize the form with values from the Zustand store or default values
    const form = useForm({
        defaultValues: rawDataSearchForm || defaultSearchValues,
    });
    
    // Initialize the Zustand store if it's empty
    useEffect(() => {
        if (!rawDataSearchForm) {
            setRawDataSearchForm(defaultSearchValues);
        }
    }, [rawDataSearchForm, setRawDataSearchForm]);
    
    // Use a custom onChange handler for the autoIdType function
    const handleAutoIdType = (value: string) => {
        autoIdType(value, form);
        // Get the current form values after autoIdType has potentially updated them
        const currentValues = form.getValues();
        // Update the store with the current ID type
        updateRawDataSearchForm({ idType: currentValues.idType });
    };
    
    // Watch for changes to the dataType field and update the Zustand store
    const dataTypeValue = form.watch('dataType');
    useEffect(() => {
        if (dataTypeValue) {
            updateRawDataSearchForm({ dataType: dataTypeValue });
        }
    }, [dataTypeValue, updateRawDataSearchForm]);
    
    // Use local state for dates to avoid infinite loops with Zustand
    // TODO: Move this back to the Zustand store once we resolve the infinite update loop issue
    const [dateRange, setDateRange] = useState<{
        startDate?: Date;
        endDate?: Date;
        from?: string;
        to?: string;
    }>({});

    const dataTypes = [
        {
        value: 'all',
        label: 'All',
        children: [
            {
                value: 'Member Details',
                label: 'Member Details',
                children: [
                    {
                        value: DataType.M,
                        label: 'Member',
                    },
                    {
                        value: DataType.RR,
                        label: 'Rally Relationship',
                    },
                    {
                        value: DataType.ER,
                        label: 'Enterprise ID Relationship',
                    },
                    {
                        value: DataType.MP,
                        label: 'Member Plan',
                    },
                    {
                        value: DataType.MMD,
                        label: 'Member MetaData',
                    },
                    {
                        value: DataType.RTR,
                        label: 'Revoked Tokens',
                    }
                ],
            },
            {
                value: 'Device Details',
                label: 'Device Details',
                children: [
                    {
                        value: DataType.MD,
                        label: 'Member Device',
                    },
                    {
                        value: DataType.DE,
                        label: 'Device Events',
                    }
                ],
            },
            {
                value: 'Quests',
                label: 'Quests',
                children: [
                    {
                    value: DataType.QD,
                    label: 'Quest Definition',
                    },
                    {
                    value: DataType.MQ,
                    label: 'Member Quest',
                    },
                    {
                    value: DataType.MQC,
                    label: 'Member Quest Calculation',
                    },
                    {
                    value: DataType.MQD,
                    label: 'Member Quest Display',
                    },
                    {
                    value: DataType.MQP,
                    label: 'Member Quest Progress',
                    },
                    {
                    value: DataType.MQR,
                    label: 'Member Quest Reward',
                    }
                ],
            },
            {
                value: 'Campaigns',
                label: 'Campaigns',
                children: [
                    {
                    value: DataType.SD,
                    label: 'Saga Definition',
                    },
                    {
                    value: DataType.CD,
                    label: 'Campaign Definition',
                    },
                    {
                    value: DataType.MCF,
                    label: 'Member Campaign Focus',
                    },
                    {
                    value: DataType.MCP,
                    label: 'Member Campaign Progress',
                    }
                ],
            },
            {
                value: 'Activity',
                label: 'Activity',
                children: [
                    {
                        value: DataType.AR,
                        label: 'Attestation Record',
                    },
                    {
                        value: DataType.GT,
                        label: 'Garmin Transaction',
                    }
                ],
            }
        ],
        },
    ];

    const resetForm = () => {
        form.reset(defaultSearchValues)
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function onSearchSubmit(data : any, e : Event) {
        toast.clean;
        setIsLoading(true);
        e.preventDefault();
        
        // Save current form values to Zustand store (excluding date fields)
        setRawDataSearchForm({
            idType: data.idType,
            idValue: data.idValue,
            dataType: data.dataType,
            activityType: data.activityType
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const requestData = `idType=${data.idType}&Search_Input=${data.idValue}${data.dates?.from ? `&Start_Date=${data.dates.from.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, '$3-$1-$2')}` : ''}${data.dates?.to ? `&End_Date=${data.dates.to.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, '$3-$1-$2')}` : ''}${data.activityType ? `&AE=on&activityType=${data.activityType}` : ''}&${data.dataType.map((t : any) => `${t}=on`).join('&')}`;

        await axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
            requestData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((r: any) => {
            console.log('Raw response:', r.data);
            const response = r.data;
            if (response.length === 0) {
                const searchParams = new URLSearchParams(requestData);
                const user = searchParams.get('Search_Input');
                const id = searchParams.get('idType');
                toast.clean;
                toast.show({
                    message: `No records found for ${user} under ${id} `,
                    variant: 'error',
                    autoClose: false
                });
            } else if (setResults) {
                setResults({ data: response as SearchResult[] });
            }
        })
        .catch(() => {
            alert('Search failed to load, please try again');
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const [isLoading, setIsLoading] = useState(false);

    const setFormDates = (start: Date | undefined, end: Date | undefined) => {
        if (!start || !end) return;
        
        const formattedDates = {
            from: formatDateToMMDDYYYY(start),
            to: formatDateToMMDDYYYY(end)
        };
        
        // Update form values
        form.setValue('dates', formattedDates);
        
        // Update local state instead of Zustand store
        // TODO: Move this back to the Zustand store once we resolve the infinite update loop issue
        setDateRange({
            startDate: start,
            endDate: end,
            from: formattedDates.from,
            to: formattedDates.to
        });
    };

    const setToCurrentMonth = () => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFormDates(startDate, endDate);
    };

    const setToCurrentYear = () => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), 0, 1);
        const endDate = new Date(date.getFullYear(), 11, 31);
        setFormDates(startDate, endDate);
    };

    const setToLast30Days = () => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30);
        const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        setFormDates(startDate, endDate);
    };

    const setToLast7Days = () => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
        const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        setFormDates(startDate, endDate);
    };

    return (
        <FormProvider state={form} onSubmit={onSearchSubmit} onError={undefined}>
            <div style={{display: 'block', minHeight: '85px', marginBottom: '5px'}}>
                <div style={{minWidth: '50%', display: 'inline-block', float: 'left', marginRight: '1%'}}>
                    <TextInput
                        label='Member ID'
                        placeholder='Member ID'
                        model='idValue'
                        isClearable
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value;
                            updateRawDataSearchForm({ idValue: value });
                            handleAutoIdType(value);
                        }}
                        onClear={() => {
                            updateRawDataSearchForm({ idValue: '' });
                        }}
                        validators={{ required: true }}
                    />
                </div>
                <div style={{minWidth: '15%', display: 'inline-block'}}>
                    <SelectInput
                        label='ID Type'
                        model='idType'
                        validators={{ required: true }}
                        options={[
                            { label: MemberIdType.EID, value: MemberIdType.EID },
                            { label: MemberIdType.RALLY_ID, value: MemberIdType.RALLY_ID },
                            { label: MemberIdType.NAVI_ID, value: MemberIdType.NAVI_ID },
                            { label: MemberIdType.HSID, value: MemberIdType.HSID },
                        ]}
                        // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
                        onChange={(value: string | number | Function, selectedOption: Record<string, any>) => {
                            if (typeof value === 'string' && Object.values(MemberIdType).includes(value as MemberIdType)) {
                                updateRawDataSearchForm({ idType: value as MemberIdType });
                            }
                        }}
                    />
                </div>
            </div>
            <div style={{display: 'block', height: '100px'}}>
                <div style={{display: 'inline-block', float: 'left'}}>
                    <DateInputRange
                        model='dates'
                        subtext=''
                        startDateLabel='Start Date'
                        endDateLabel='End Date'
                        hasClearButton={true}
                        onChange={(values) => {                            // Handle various possible types from the updated Abyss package
                            if (values && typeof values === 'object') {
                                const dateValues = values as { from?: string | Date; to?: string | Date };
                                const startDate = dateValues.from ? new Date(dateValues.from.toString()) : undefined;
                                const endDate = dateValues.to ? new Date(dateValues.to.toString()) : undefined;
                                
                                // Update local state instead of Zustand store
                                setDateRange({
                                    startDate,
                                    endDate,
                                    from: formatDateToMMDDYYYY(startDate),
                                    to: formatDateToMMDDYYYY(endDate)
                                });
                            } else {
                                // Clear dates in local state
                                setDateRange({
                                    startDate: undefined,
                                    endDate: undefined,
                                    from: '',
                                    to: ''
                                });
                            }
                        }}
                    />
                </div>
                <div style={{display: 'inline-block', marginTop: '10px', marginLeft: '5px'}}>
                    <div style={{display: 'inline-block', width: '150px'}}>
                        <Button type='button' size='$sm' onClick={() => setToCurrentMonth()}>Current Month</Button>
                        <Button type='button' size='$sm' style={{marginTop: '5px'}} onClick={() => setToCurrentYear()}>Current Year</Button>
                    </div>
                    <div style={{display: 'inline-block', width: '135px'}}>
                        <Button type='button' size='$sm' onClick={() => setToLast30Days()}>Last 30 Days</Button>
                        <Button type='button' size='$sm' style={{marginTop: '5px'}} onClick={() => setToLast7Days()}>Last 7 Days</Button>
                    </div>
                </div>
            </div>
            <div style={{display: 'block', marginBottom: '8px'}}>
                <div style={{minWidth: '235px', display: 'inline-block', float: 'left'}}>
                    <SelectInput
                        label='Activity Type'
                        placeholder='(Optional) Pick one'
                        isClearable
                        isSearchable
                        model='activityType'
                        onClear={() => {
                            updateRawDataSearchForm({ activityType: null });
                        }}
                        // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
                        onChange={(value: string | number | Function, _: Record<string, any>) => {
                            if (value === null) {
                                updateRawDataSearchForm({ activityType: null });
                            } else if (typeof value === 'string' && Object.values(ActivityType).includes(value as ActivityType)) {
                                updateRawDataSearchForm({ activityType: value as ActivityType });
                            }
                        }}
                        options={[
                            { value: ActivityType.ACTIVE_MINUTES, label: 'Active Minutes' },
                            { value: ActivityType.MODERATE_ACTIVE_MINUTES, label: 'Moderate Active Minutes' },
                            { value: ActivityType.VIGOROUS_ACTIVE_MINUTES, label: 'Vigorous Active Minutes' },
                            { value: ActivityType.CALORIES, label: 'Calories' },
                            { value: ActivityType.DISTANCE, label: 'Distance' },
                            { value: ActivityType.ELEVATION, label: 'Elevation' },
                            { value: ActivityType.FLOORS, label: 'Floors' },
                            { value: ActivityType.STEPS, label: 'Steps' },
                            { value: ActivityType.QUEST_COMPLETION, label: 'Quest Completion' },
                            { value: ActivityType.SLEEP, label: 'Sleep' },
                            { value: ActivityType.STROKE, label: 'Stroke' },
                            { value: ActivityType.PAIR_DEVICE, label: 'Pair Device' },
                            { value: ActivityType.TIME_IN_BED, label: 'Time In Bed' },
                        ]}
                    />
                </div>
                <div style={{minWidth: '350px', display: 'inline-block'}}>
                    <CheckboxTree 
                        label='Data Type Filter' 
                        nodes={dataTypes} 
                        model='dataType' 
                        showExpandAll 
                    />
                </div>
            </div>
            <Layout.Group>
                {form.getValues().dataType.length > 7 && (!dateRange.from || !dateRange.to) ?
                    <Tooltip content='Are you sure you want to search so many data fields without a date range?'>
                        <TopMarginWrapper>
                            <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Searching'>
                                Search
                                <IconSymbol icon='warning' size='18px' style={{ marginLeft: '10px' }}/>
                            </Button>
                        </TopMarginWrapper>
                    </Tooltip>
                    :
                    <TopMarginWrapper>
                        <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Searching'>
                            Search
                        </Button>
                    </TopMarginWrapper>
                }

                <TopMarginWrapper>
                    <Button type='button' onClick={() => resetForm()}>
                        Reset Form
                    </Button>
                </TopMarginWrapper>
                <TopMarginWrapper>
                    <Button type='button' onClick={() => setResults && setResults({ data: [] })}>
                        Clear Results
                    </Button>
                </TopMarginWrapper>
            </Layout.Group>
        </FormProvider>
    );
};

export default RawDataSearchForm;
