import { createRouter } from '@abyss/web/tools/createRouter';
import { Router } from '@abyss/web/ui/Router';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Issues from './pages/Issues';

/**
 * Define the application routes
 */
const Routes = () => {
  return (
    <Router.Routes>
      <Router.Route path="/" element={<Home />} />
      <Router.Route path="/Dashboard" element={<Dashboard />} />
      <Router.Route path="/Issues" element={<Issues />} />
    </Router.Routes>
  );
};

/**
 * Create the router instance
 */
export const router = createRouter(Routes);
