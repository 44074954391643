import { pkSk } from "./sharedTypes";

export enum CampaignType {
    OTA = 'One Time Action',
    DAILY = 'Daily Quest',
    CAMPAIGN = 'Challenge',
}

export enum CampaignStartDateType {
    START = 'Started',
    FOCUS = 'Enrolled',
}

export enum RallyActivityId {
    ACTIVITY_5IN7 = 'UHCR.FIT.WKLY.DAILYGOAL.5IN7.CHALLENGE.COMPLETE',
    DEVICE_PAIRED = 'DEVICE.PAIRED',
    DAILY_FITNESS_ACHIEVED = 'DAILY.FITNESS.ACHIEVED',
    DAILY_FITNESS_PARTICIPATION = 'DAILY.FITNESS.PARTICIPATION',
    FLU_SHOT = 'UHCR.GOTFLUSHOT.ATTEST',
    SLEEP_5IN7 = 'UHCR.SLEEP.WKLY.7HRS.5IN7.CHALLENGE.COMPLETE',
    SLEEP_TRACKING = 'SLEEP.TRACKING.COMPLETE',
}

export enum DataType {
    M = 'M',
    MMD = 'MMD',
    MD = 'MD',
    RR = 'RR',
    ER = 'ER',
    MP = 'MP',
    VOID_MP = 'VOID_MP',
    SD = 'SD',
    CD = 'CD',
    QD = 'QD',
    MCF = 'MCF',
    MCP = 'MCP',
    MQ = 'MQ',
    MQD = 'MQD',
    MQC = 'MQC',
    MQP = 'MQP',
    MQR = 'MQR',
    AE = 'AE',
    GT = 'GT',
    DE = 'DE',
    AR = 'AR',
    RTR = 'RTR',
    MPR = 'MPR',
    // Additional data types for keystone search
    HHEIDR = 'HHEIDR',     // Household Enterprise ID Relationship
    GPSMPE = 'GPSMPE',     // GPS Member Plan Event
    APA = 'APA',           // Archived Plan Association
    MPA = 'MPA',           // Member Plan Association
    APR = 'APR',           // Archived Program Registration
    MI = 'MI',             // Member Info
    MAPSPMapping = 'MAPSPMapping', // MAPS Plan Mapping
    MAPSGMapping = 'MAPSGMapping', // MAPS Group Mapping
    P = 'P',               // Plan
    G = 'G',               // Group
}

export type ChallengeView = {
    id: string;
    name: string;
    description: string;
    type: string;
    progress: string;
    progressPercent: string | JSX.Element;
    active: string | JSX.Element;
    startFocusDate: string;
    lastCompletionDate: string;
    campaign: SS_Campaign;
}

export type MemberPlan = pkSk & {
    planName: string;
    planId: string;
    planStartDateTime: string;
    planEndDateTime: string;
    termDateTime: string;
    effectiveDateTime: string;
    lastQueriedDateTime: string;
    clientId: string;
    createdDateTime: string;
};

export type SS_MemberPlan = MemberPlan & {
    skEndDateTime: string;
    adjustedSkEndDateTime: string;
    adjustedEffectiveDateTime: string;
    // sagas: Saga[]; //TODO: future feature - when the missing PAD saga gets created
    campaigns: SS_Campaign[];
};

export type Saga = pkSk & {
    startDateTime?: string;
    campaignIds: pkSk[];
    prerequisiteCampaignIds: pkSk[];
    campaigns: Campaign[];
};

export type Campaign = pkSk & {
    skEndDateTime: string;
    id: string;
    campaignType: string;
    category: string;
    completionType: string;
    questDefinitionId: pkSk;
    startDateTime: string;
    repeatGoal: string;
    isFocusExempt: boolean;
    focusableRequirements?: string;
    rallyActivityId: string;
};

export type SS_Campaign = Campaign & {
    mcf?: MemberCampaignFocus;
    mcp?: MemberCampaignProgress;
    qd?: Quest;
    quests: SS_MemberQuest[];
}

export type MemberCampaignFocus = pkSk & {
    startDateTime: string;
    campaignDefinitionId: pkSk;
}

export type MemberCampaignProgress = pkSk & {
    progressValue: number;
    progressPercentage: number;
    lastCalculationDateTime: string;
    completionDateTime?: string;
    campaignDefinitionId: pkSk;
    questDefinitionId: pkSk;
    campaignType: string;
    campaignCategory: string;
    completionType: string;
    repeatGoal?: number;
    startDateTime: string;
}

export type Quest = pkSk & {
    source: string;
    goal: number;
    name: string;
    description: string;
    creationDateTime: string;
    category: string;
    displayActivity: string;
    assignmentRequirements: object;
    duration: string;
    startDateTime: string;
    questType: string;
    unit: string;
    trackerType?: string;
    calculationLambda: string;
    calculationRequirements?: CalculationRequirements;
    calculationActivity: string;
    icon: string;
    rallyProductEventString?: string;
    rallyProductEventMap?: object;
    rallyActivityId?: string;
}

export type MemberQuest = pkSk & {
    startDateTime: string;
    questId: pkSk
    questType: string;
    calculationActivity: string;
    rallyActivityId?: string;
    parentMemberQuestSk?: MemberQuestSk;
    childrenMemberQuestSks?: MemberQuestSk[];
}

export type SS_MemberQuest = MemberQuest & {
    skEndDateTime: string;
    qd?: Quest;
    mqc?: MemberQuestCalculation;
    mqd?: MemberQuestDisplay;
    mqp?: MemberQuestProgress;
    mqr?: MemberQuestReward;
    quests: SS_MemberQuest[];
    activities: ActivityEvent[];
}

export type MemberQuestSk = {
    endDateTime: string;
    memberQuestId: string;
}

export type CalculationRequirements = {
    dependentMemberQuestProgressIds: pkSk[];
}

export type MemberQuestCalculation = pkSk & {
    goal: number;
    calculationLambda: string;
    calculationRequirements?: CalculationRequirements;
    questId: pkSk;
    questType: string;
    startDateTime: string;
}

export type MemberQuestDisplay = pkSk & {
    name: string;
    description: string;
    unit: string;
    trackerType?: string;
    category: string;
    activity: string;
    icon: string;
    startDateTime: string;
    rallyActivityId?: string;
}

export type MemberQuestProgress = pkSk & {
    progressPercent: number;
    progressAmount: number;
    calculationRequirements?: CalculationRequirements;
    lastCalculationDateTime: string;
    completionDateTime?: string;
    goal: number;
    device?: string;
    rallyProductEventString?: string;
    rallyProductEventMap?: object;
}

export type MemberQuestReward = pkSk & {
    memberDataSourceId: string;
    rallyPostDateTime: string;
    rallyTransactionId: string;
    rallyProductEventString?: string;
    rallyProductEventMap?: object;
    questType: string;
    resendPayment: boolean;
    reportedCompletionDateTime: string;
}

export type ActivityEvent = pkSk & {
    payloadId: string;
    deviceId: string;
    amount: number;
    receivedDateTime: string;
    partner?: string;
    transactionId?: string;
}

export type MemberProgramRegistration = pkSk & {
    createdDateTime: string;
    eligibilityEffectiveDate: string;
    lineOfBusiness: string;
    rewardsEffectiveDate: string;
    rewardsTermDate: string;
    preEffectiveDays: number;
    postEffectiveDays: number;
    rewardsBundleId: string;
};

// export type MemberPlanAssociation = pkSk & {};
