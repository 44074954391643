import React from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { Card } from '@abyss/web/ui/Card';
import { Table } from '@abyss/web/ui/Table';
import { MemberIdModel } from '../constants/types';
import InlinePopover from './InlinePopover';
import CopyButton from './CopyButton';
import { Cell, HeaderCell } from '../styles/styles';

export interface MemberIdCardProps {
    member: MemberIdModel;
};

const MemberIdCard: React.FC<MemberIdCardProps> = (props: MemberIdCardProps) => {
    const ldClient = useLDClient();
    const isShowEnterpriseIdRelationshipMissing = ldClient?.variation('release_ss_enterpriseid_relationship_missing', true) as boolean;
    const { enterpriseId, hasER, rallyId, hsidUuid, naviId } = props.member;

    return (
        <SizeWrapper>
            <Card size='small'>
                <Table.Container title='Member ID Table'>
                    <Table.TableBody>
                        <Table.Row>
                            <HeaderCell $header>Enterprise ID</HeaderCell>
                            <Cell>
                                {enterpriseId ?? '-'}
                                <CopyButton value={enterpriseId}/>
                                {isShowEnterpriseIdRelationshipMissing &&
                                <InlinePopover
                                        title={'Missing a backend Enterprise ID Relationship record'}
                                        content={
                                            <div>
                                                <span>
                                                    Please create an incident ticket to assignment group &apos;<strong>UHC Rewards</strong>&apos; mentioning &apos;<strong>Incorrect/missing Enterprise ID Relationship record</strong>&apos; for this member.
                                                </span>
                                            </div>
                                        }
                                        displayed={hasER}
                                        icon={'warning'}
                                        width={415}
                                    />
                                }
                            </Cell>
                        </Table.Row>
                        <Table.Row>
                            <HeaderCell $header>Rally ID (RID)</HeaderCell>
                            <Cell>
                                {rallyId ?? '-'}
                                <CopyButton value={rallyId} />
                            </Cell>
                        </Table.Row>
                        <Table.Row>
                            <HeaderCell $header>HSID</HeaderCell>
                            <Cell>
                                {hsidUuid ?? '-'}
                                <CopyButton value={hsidUuid} />
                            </Cell>
                        </Table.Row>
                        <Table.Row>
                            <HeaderCell $header>Navi ID (MID)</HeaderCell>
                            <Cell>
                                {naviId ?? '-'}
                                <CopyButton value={naviId} />
                            </Cell>
                        </Table.Row>
                    </Table.TableBody>
                </Table.Container>
            </Card>
        </SizeWrapper>
    );
};

const SizeWrapper = styled.div`
    min-width: 500px;
`;

export default MemberIdCard;
