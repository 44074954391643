import { ActivityEvent, Campaign, DataType, MemberCampaignFocus, MemberCampaignProgress, MemberPlan, MemberQuest, MemberQuestCalculation, MemberQuestDisplay, MemberQuestProgress, MemberQuestReward, Quest, SS_MemberQuest } from '../constants/challengeTypes';
import { MemberIdType, MemberSearchResultsModel, SearchResponse } from '../constants/types';
import { getName } from '../helpers/challengeUtils';
import { utcToNaiveEndDate, utcToNaiveStartDate } from '../helpers/dateUtils';
import { MemberSearchFormState } from '../store/formStore';
import { AMAZON_HALO, APPLE_HEALTH, FITBIT, GARMIN, NO_PARTNER } from '../constants/partners';
import { ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES, VIGOROUS_ACTIVE_MINUTES } from '../constants/activityTypes';
import { getMemberByHsid, getMemberByEnterpriseId } from './eimpService';
import { searchMemberData, searchQuestData, searchActivityEvents } from './searchService';

export interface SearchFlags {
    challengeConsoleLogging: boolean;
    filterOutIncorrectActiveMinutes: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processSearch = async (token: string, data: MemberSearchFormState, toast: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, setData: React.Dispatch<React.SetStateAction<SearchResponse | null>>) => {
    let success = true;
    const errorTitle = `Partial search failed to load for ID: ${data.idValue}`;
    let errorMessage = 'Some data may have inaccuracies. Please try again. For repeated failures, please report the issue under the "ISSUES" tab.';
    
    // Keep track of the original search parameters
    const originalIdType = data.idType;
    const originalIdValue = data.idValue;
    
    // Create a working copy of the search parameters
    let searchIdType = data.idType;
    let searchIdValue = data.idValue;

    toast.clean();
    setData(null);

    let responseData: SearchResponse = { Count: 0, ScannedCount: 0 } as SearchResponse;

    // If this is an HSID search, we need to use the EIMP API first
    if (searchIdType === MemberIdType.HSID) {
        const eimpResult = await getMemberByHsid(token, searchIdValue);
        
        if (eimpResult.status === 'success' && eimpResult.data?.enterpriseId) {
            // We found a single match with an Enterprise ID, continue search with that EID
            searchIdType = MemberIdType.EID;
            searchIdValue = eimpResult.data.enterpriseId;
            responseData.EIMP = eimpResult.data.memberInfo;
        } else {
            toast.show({
                message: eimpResult.message || 'Error searching by HSID',
                variant: 'error',
                autoClose: false
            });
            setIsLoading(false);
            return;
        }
    }
    
    try {
        // Step 1: Get non-MQ data types
        const nonMQDataTypes = data.dataType.filter((dt: string) => dt.indexOf("MQ") === -1);
        if (nonMQDataTypes.length > 0) {
            const memberDataResult = await searchMemberData(token, searchIdType, searchIdValue, true);
            
            if (!memberDataResult.success) {
                success = false;
                errorMessage += ` Error: ${memberDataResult.error}.`;
            } else if (memberDataResult.data) {
                const memberData = memberDataResult.data as Partial<SearchResponse>;
                if (memberData.Count === 0) {
                    toast.clean();
                    toast.show({
                        message: `No records found for ${originalIdValue} under ${originalIdType}`,
                        variant: 'error',
                        autoClose: false
                    });
                } else {
                    responseData = {
                        ...responseData,
                        ...memberData,
                        Count: responseData.Count + (memberData.Count || 0),
                        ScannedCount: responseData.ScannedCount + (memberData.ScannedCount || 0)
                    };
                }
            }
        }
        
        // Step 2: Get MQ data types
        const mqDataTypes = data.dataType.filter((dt: string) => dt.indexOf("MQ") > -1);
        if (mqDataTypes.length > 0) {
            const questDataResult = await searchQuestData(token, searchIdType, searchIdValue);
            
            if (!questDataResult.success) {
                success = false;
                if (questDataResult.errors && questDataResult.errors.length > 0) {
                    errorMessage += ` Error: ${questDataResult.errors.join(', ')}.`;
                }
            } else if (questDataResult.data) {
                const questData = questDataResult.data as Partial<SearchResponse>;
                if ((questData.Count || 0) > 0) {
                    responseData = {
                        ...responseData,
                        ...questData,
                        Count: responseData.Count + (questData.Count || 0),
                        ScannedCount: responseData.ScannedCount + (questData.ScannedCount || 0)
                    };
                }
            }
        }
        
        // Step 3: Get activity events if needed
        if (data.dataType.indexOf(DataType.AE) > -1 && data.activityTypes.length > 0) {
            const activityResult = await searchActivityEvents(token, searchIdType, searchIdValue, data.activityTypes);
            
            if (!activityResult.success) {
                success = false;
                errorMessage += ` Error: ${activityResult.error}.`;
            } else if (activityResult.data) {
                const activityData = activityResult.data as Partial<SearchResponse>;
                if ((activityData.Count || 0) > 0) {
                    responseData = {
                        ...responseData,
                        ...activityData,
                        Count: responseData.Count + (activityData.Count || 0),
                        ScannedCount: responseData.ScannedCount + (activityData.ScannedCount || 0)
                    };
                }
            }
        }
        
        // For non-HSID searches, if we have data and an EID, query EIMP to get additional member information
        if (originalIdType !== MemberIdType.HSID && responseData.Count > 0) {
            // Find the Enterprise ID in the response data
            let enterpriseId = '';
            if (originalIdType === MemberIdType.EID) {
                // If we searched by EID, use the original search value
                enterpriseId = originalIdValue;
            } else if (responseData.M?.length > 0) {
                // Try to find the EID in the response data
                const member = responseData.M.find((m: { sk: string; enterpriseId?: string }) => m.sk === 'MemberInformation');
                if (member?.enterpriseId) {
                    enterpriseId = member.enterpriseId;
                }
            }

            // If we have an Enterprise ID, query EIMP to get additional member information
            if (enterpriseId) {
                try {
                    const eimpResult = await getMemberByEnterpriseId(token, enterpriseId);
                    
                    if (eimpResult.status === 'success' && eimpResult.data?.memberInfo) {
                        // Store the EIMP response in the responseData
                        responseData.EIMP = eimpResult.data.memberInfo;
                    }
                } catch (error) {
                    // If EIMP query fails, just continue without the additional information
                    console.warn('Failed to get additional member information from EIMP:', error);
                }
            }
        }
        
        // Set the data and loading state
        setData(responseData);
        setIsLoading(false);
        
        // Show error message if any requests failed
        if (!success) {
            toast.clean();
            toast.show({
                title: errorTitle,
                message: errorMessage,
                variant: 'error',
                autoClose: false
            });
        }
    } catch (error) {
        // Handle any unexpected errors
        setIsLoading(false);
        toast.clean();
        toast.show({
            title: errorTitle,
            message: `Unexpected error: ${error instanceof Error ? error.message : 'Unknown error'}`,
            variant: 'error',
            autoClose: false
        });
    }

    // Promise handling is now done inside the try/catch block above
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processSearchResults = (data: SearchResponse | null | undefined, searchFlags: SearchFlags) => {
    const memberSearchResults: MemberSearchResultsModel = {
        memberIds: {
            naviId: '',
        },
        deviceTypePairDates: [],
        demographics: {},
        additionalData: {
            lastActivityReceived: {},
            lastCompletedQuest: {},
        },
        plans: [],
    };

    if(data === null || data === undefined) {
        return memberSearchResults;
    }

    return processSearchResultsSorted(data, memberSearchResults, searchFlags);
};

export const processSearchResultsSorted = (data: SearchResponse, memberSearchResults: MemberSearchResultsModel, searchFlags: SearchFlags) => {
    searchFlags.challengeConsoleLogging && console.log(data);

    if(data.MD && data.MD.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        memberSearchResults.deviceTypePairDates = data.MD.map((md: any) => ({
                dataType: md.sk.split('#')[0],
                partner: md.sk.split('#')[1],
                firstPairDate: md.devicePairDateTimes && md.devicePairDateTimes.length > 0 ? md.devicePairDateTimes[0] : '-',
                latestPairDate: md.devicePairDateTimes && md.devicePairDateTimes.length > 0 ? md.devicePairDateTimes.at(-1) : '-',
            })
        );
    }

    if(data.M && data.M.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const member = data.M.find((m: any) => m.sk === 'MemberInformation');
        if(member !== undefined) {
            memberSearchResults.memberIds.naviId = member['pk'].substring(member['pk'].indexOf('#') + 1);
            memberSearchResults.memberIds.enterpriseId = member['enterpriseId'];
            memberSearchResults.memberIds.rallyId = member['rallyId'];
            memberSearchResults.memberIds.hsidUsername = member['hsidUsername'];
            memberSearchResults.memberIds.hsidUuid = member['hsidUuid'];
            memberSearchResults.demographics.firstName = member['firstName'];
            memberSearchResults.demographics.lastName = member['lastName'];
            memberSearchResults.demographics.dob = member['birthDate'];
            memberSearchResults.demographics.registeredDate = member['registerDate'];
            memberSearchResults.demographics.gender = member['gender'];
            memberSearchResults.demographics.policyId = member['policyNumber'];
            memberSearchResults.demographics.planName = member['planName'];
            memberSearchResults.demographics.lob = member['lineOfBusiness'];
            memberSearchResults.demographics.programStartDate = member['programStartDate'];
            memberSearchResults.demographics.utcOffset = member['utcOffset'];
            
            // If we have EIMP data, use it to supplement or override member information
            if (data.EIMP) {
                // Add HSID from EIMP if available and not already set
                if (!memberSearchResults.memberIds.hsidUuid && 
                    data.EIMP.identifiers?.hsid_identifiers?.length > 0) {
                    memberSearchResults.memberIds.hsidUuid = data.EIMP.identifiers.hsid_identifiers[0].hsid;
                }
                
                // Add name information if available and not already set
                if (!memberSearchResults.demographics.firstName && data.EIMP.givenName) {
                    memberSearchResults.demographics.firstName = data.EIMP.givenName;
                }
                if (!memberSearchResults.demographics.lastName && data.EIMP.familyName) {
                    memberSearchResults.demographics.lastName = data.EIMP.familyName;
                }
                
                // Add DOB if available and not already set
                if (!memberSearchResults.demographics.dob && data.EIMP.birthDate) {
                    memberSearchResults.demographics.dob = data.EIMP.birthDate;
                }
                
                // Add gender if available and not already set
                if (!memberSearchResults.demographics.gender && data.EIMP.gender) {
                    memberSearchResults.demographics.gender = data.EIMP.gender;
                }
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mmd = data.M.find((m: any) => m.sk === 'MMD');
        if(mmd !== undefined) {
            memberSearchResults.additionalData.lastSyncDate = mmd['lastActivitySyncDateTime'];
            if(!memberSearchResults.additionalData.lastActivityReceived) memberSearchResults.additionalData.lastActivityReceived = {};
            memberSearchResults.additionalData.lastActivityReceived.date = mmd['latestActivityDataDateTime'];
            memberSearchResults.additionalData.assignedUpToDate = mmd['assignedUpToDate'];
        }
    }

    if(data.RR && data.RR.length > 0) {
        for(const rr of data.RR) {
            memberSearchResults.memberIds.hasRR = true;
            if(memberSearchResults.memberIds.hasRRSK === undefined || memberSearchResults.memberIds.hasRRSK === false) {
                memberSearchResults.memberIds.hasRRSK = rr['sk'] === 'SK';
            }
        }
    }

    if(data.ER && data.ER.length > 0) {
        memberSearchResults.memberIds.hasER = true;
    }

    memberSearchResults.plans = data.MP?.map((row: MemberPlan) => {
        const skEndDateTime = row.sk.split('#')[0];
        return ({
            ...row,
            row: row,
            skEndDateTime: skEndDateTime,
            adjustedSkEndDateTime: utcToNaiveEndDate(skEndDateTime),
            adjustedEffectiveDateTime: utcToNaiveStartDate(row.effectiveDateTime),
            campaigns: [],
        });
    }) ?? [];

    memberSearchResults.plans.map((plan) => {
        plan.campaigns = data.CD?.filter((c: Campaign) => plan.adjustedEffectiveDateTime >= c.startDateTime && plan.adjustedSkEndDateTime <= c.sk.split('#')[0]).map((c: Campaign) => ({
            ...c,
            row: c,
            skEndDateTime: c.sk.split('#')[0],
            id: c.sk.split('#')[1],
            quests: [],
        })) ?? [];
    });

    data.MCF?.map((mcf: MemberCampaignFocus) => {
        const plan = memberSearchResults.plans.find((p) => mcf.startDateTime >= p.adjustedEffectiveDateTime && mcf.startDateTime <= p.adjustedSkEndDateTime);
        if(!plan) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcf.pk}:${mcf.sk} with startDateTime ${mcf.startDateTime} does not belong to any plan.`);
            return;
        }
        const campaign = plan.campaigns.find((c) => c.pk === mcf.campaignDefinitionId.pk && c.sk === mcf.campaignDefinitionId.sk);
        if(!campaign) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcf.pk}:${mcf.sk} with startDateTime ${mcf.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
            return;
        }
        campaign.mcf = {
            ...mcf,
            row: mcf,
        };
    });

    data.MCP?.map((mcp: MemberCampaignProgress) => {
        const plan = memberSearchResults.plans.find((p) => mcp.startDateTime >= p.adjustedEffectiveDateTime && mcp.startDateTime <= p.adjustedSkEndDateTime);
        if(!plan) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcp.pk}:${mcp.sk} with startDateTime ${mcp.startDateTime} does not belong to any plan.`);
            return;
        }
        const campaign = plan.campaigns.find((c) => c.pk === mcp.campaignDefinitionId.pk && c.sk === mcp.campaignDefinitionId.sk);
        if(!campaign) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcp.pk}:${mcp.sk} with startDateTime ${mcp.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
            return;
        }
        campaign.mcp = {
            ...mcp,
            row: mcp,
        };
    });

    const topLevelMemberCampaigns = memberSearchResults.plans.flatMap((p) => p.campaigns);

    data.QD?.map((q: Quest) => {
        topLevelMemberCampaigns.filter((c) => c.questDefinitionId.pk === q.pk && c.questDefinitionId.sk === q.sk)
            .map((c) => c.qd = {
                ...q,
                row: q,
        });
    });

    const leftOverQuests: MemberQuest[] = [];

    data.MQ?.map((mq: MemberQuest) => {
        const plan = memberSearchResults.plans.find((p) => mq.startDateTime >= p.adjustedEffectiveDateTime && mq.startDateTime <= p.adjustedSkEndDateTime);
        if(!plan) {
            leftOverQuests.push(mq);
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mq.pk}:${mq.sk} with startDateTime ${mq.startDateTime} does not belong to any plan.`);
            return;
        }
        const campaign = plan.campaigns.find((c) => c.questDefinitionId.pk === mq.questId.pk && c.questDefinitionId.sk === mq.questId.sk);
        if(!campaign) {
            leftOverQuests.push(mq);
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mq.pk}:${mq.sk} with startDateTime ${mq.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
            return;
        }
        const activities = mq.calculationActivity === 'QuestCompletion' ? [] :
            filterActivitiesByTypeAndDateRange(data.AE, mq.calculationActivity, mq.startDateTime, mq.sk.split('#')[0], searchFlags).map((ae: ActivityEvent) => ({
                ...ae,
                row: ae,
            })) ?? [];

        campaign.quests.push({
            ...mq,
            row: mq,
            skEndDateTime: mq.sk.split('#')[0],
            quests: [],
            activities,
        });
        campaign.quests.sort((a: SS_MemberQuest, b: SS_MemberQuest) => a.startDateTime > b.startDateTime ? -1 : 1);
    });

    const topLevelMemberQuests = topLevelMemberCampaigns.flatMap((c) => c.quests);
    processChildQuests(data, topLevelMemberQuests, leftOverQuests, searchFlags);

    searchFlags.challengeConsoleLogging && console.log(leftOverQuests);
    searchFlags.challengeConsoleLogging && console.log(memberSearchResults.plans);

    const skDates = topLevelMemberQuests
        .filter((q) => q.mqp !== undefined && q.mqp.completionDateTime !== undefined && q.mqp.completionDateTime !== null)
        .sort((a, b) => (a.mqp?.completionDateTime as string) > (b.mqp?.completionDateTime as string) ? -1 : 1);
    if(skDates.length > 0) {
        memberSearchResults.additionalData.lastCompletedQuest = {
            sk: skDates[0].mqp?.sk as string,
            date: skDates[0].mqp?.completionDateTime as string,
            name: getName(skDates[0].rallyActivityId ?? ''),
        };
    }

    return memberSearchResults;
}

const processChildQuests = (data: SearchResponse, memberQuests: SS_MemberQuest[], leftOverQuests: MemberQuest[], searchFlags: SearchFlags) => {
    memberQuests.map((mq: SS_MemberQuest) => {
        const mqId = mq.sk.split('#')[1];
        const mqc = data.MQC?.find((mqc: MemberQuestCalculation) => mqId === mqc.sk.split('#')[2]);
        if(mqc !== undefined) {
            mq.mqc = {
                ...mqc,
                row: mqc,
            };
        }

        const mqd = data.MQD?.find((mqd: MemberQuestDisplay) => mqId === mqd.sk.split('#')[2]);
        if(mqd !== undefined) {
            mq.mqd = {
                ...mqd,
                row: mqd,
            };
        }

        const mqp = data.MQP?.find((mqp: MemberQuestProgress) => mqId === mqp.sk.split('#')[2]);
        if(mqp !== undefined) {
                mq.mqp = {
                ...mqp,
                row: mqp,
            };
        }

        const mqr = data.MQR?.find((mqr: MemberQuestReward) => mqId === mqr.sk);
        if(mqr !== undefined) {
            mq.mqr = {
                ...mqr,
                row: mqr,
            };
        }

        const qd = data.QD?.find((q: Quest) => mq.questId.sk === q.sk);
        if(qd !== undefined) {
            mq.qd = {
                ...qd,
                row: qd,
            };
        }

        const childQuests: MemberQuest[] = [];
        let i = 0;
        while(i < leftOverQuests.length) {
            if(leftOverQuests[i].parentMemberQuestSk !== null && leftOverQuests[i].parentMemberQuestSk?.endDateTime + '#' + leftOverQuests[i].parentMemberQuestSk?.memberQuestId === mq.sk) {
                childQuests.push(leftOverQuests[i]);
                leftOverQuests.splice(i, 1);
            } else {
                i++;
            }
        }

        mq.activities = mq.calculationActivity === 'QuestCompletion' ? [] :
            filterActivitiesByTypeAndDateRange(data.AE, mq.calculationActivity, mq.startDateTime, mq.sk.split('#')[0], searchFlags).map((ae: ActivityEvent) => ({
                ...ae,
                row: ae,
            })) ?? [];

        childQuests.map((cq: MemberQuest) => {
            mq.quests.push({
                ...cq,
                row: cq,
                skEndDateTime: cq.sk.split('#')[0],
                quests: [],
                activities: [],
            });
        });
        mq.quests.sort((a: SS_MemberQuest, b: SS_MemberQuest) => a.startDateTime > b.startDateTime ? -1 : 1);
    });

    const childrenMemberQuests = memberQuests.flatMap((q) => q.quests);
    if(childrenMemberQuests.length > 0) {
        processChildQuests(data, childrenMemberQuests, leftOverQuests, searchFlags);
    }
}

export const filterActivitiesByTypeAndDateRange = <T extends { pk: string, sk: string, partner?: string }>(activityEvents: T[], activityType: string, startDate: string, endDate: string, searchFlags: SearchFlags): T[] => {
    if(!searchFlags.filterOutIncorrectActiveMinutes) {
        return activityEvents?.filter((ae) => ae.pk.split('#')[2].indexOf(activityType) >= 0 && ae.sk >= startDate && ae.sk <= endDate) ?? [];
    }
    return activityEvents?.filter((ae) => ae.sk >= startDate && ae.sk <= endDate && ae.pk.split('#')[2].indexOf(activityType) >= 0 &&
        (
            ![VIGOROUS_ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES, ACTIVE_MINUTES].includes(activityType)
            || ([FITBIT, GARMIN, AMAZON_HALO].includes(ae?.partner ?? NO_PARTNER) && [VIGOROUS_ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
            || ([APPLE_HEALTH].includes(ae?.partner ?? NO_PARTNER) && [MODERATE_ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
            || (![FITBIT, GARMIN, APPLE_HEALTH, AMAZON_HALO].includes(ae?.partner ?? NO_PARTNER) && [ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
        )
    ) ?? [];
}
