import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { UserPreferenceContext } from '../contexts/UserPreferenceContext';
import MemberSearchForm from '../components/MemberSearchForm';
import MemberSearchResults from '../components/MemberSearchResults';
import RawDataSearchForm, { SearchResults } from '../components/RawDataSearchForm';
import HotBar from '../components/HotBar';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import { ToastProvider } from '@abyss/web/ui/Toast';
import ActivityRecalculationCard from '../components/ActivityRecalculationCard';
import { KeystoneSearchResultsModel, MemberSearchResultsModel } from '../constants/types';
import { processSearchResults } from '../services/naviSearch';
import { processKeystoneResults } from '../services/keystoneSearch';
import KeystoneSearchForm from '../components/KeystoneSearchForm';
import KeystoneSearchResults from '../components/KeystoneSearchResults';
import KeystoneEligibilityFixCard from '../components/KeystoneEligibilityFixCard';
import RawDataResults from '../components/RawDataResults';

const Home: FC = () => {
    const [memberSearchResultsData, setMemberSearchResultsData] = useState(null);
    const [keystoneSearchResultsData, setKeystoneSearchResultsData] = useState(null);
    const [isMemberSearchLoading, setIsMemberSearchLoading] = useState(false);
    const [rawDataSearchResults, setRawDataSearchResults] = useState<SearchResults | null>(null);

    const ldClient = useLDClient();
    const { defaultTab } = React.useContext(UserPreferenceContext);
    const enableDefaultTabPreference = ldClient?.variation('enable_ss_default_tab_preference', true) as boolean;
    
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (enableDefaultTabPreference) {
            setActiveIndex(defaultTab);
        }
    }, [defaultTab, enableDefaultTabPreference]);
    const enableChallengeConsoleLogging = ldClient?.variation('enable_ss_challenge_console_logging', false) as boolean;
    const enableFilterOutIncorrectActiveMinutes = ldClient?.variation('enable_ss_filter_out_incorrect_active_minutes', true) as boolean;
    const enableKeystoneSearch = ldClient?.variation('enable_ss_keystone_search', true) as boolean;
    const enableKeystoneEligiblityFix = ldClient?.variation('enable_ss_keystone_eligibility_fix', true) as boolean;
    const memberSearchResults: MemberSearchResultsModel = useMemo(() =>
        processSearchResults(memberSearchResultsData, { challengeConsoleLogging: enableChallengeConsoleLogging, filterOutIncorrectActiveMinutes: enableFilterOutIncorrectActiveMinutes })
    , [memberSearchResultsData]);
    const keystoneSearchResults: KeystoneSearchResultsModel = useMemo(() =>
        processKeystoneResults(keystoneSearchResultsData, { challengeConsoleLogging: enableChallengeConsoleLogging })
    , [keystoneSearchResultsData]);

    return (
        <ThemeProvider>
            <ToastProvider>
                <div></div>
            </ToastProvider>
            <HotBar/>
            <PageBody fullWidth>
            <Layout.Group alignItems="top" alignLayout="left">
                <SizeWrapper>
                    <Tabs title='Search Tabs' size='$sm'
                            active={activeIndex}
                            onTabChange={(index: number) => setActiveIndex(index)}
                    >
                        <Tabs.Tab label='Member Search'>
                            <MemberSearchForm
                                setData={setMemberSearchResultsData}
                                isLoading={isMemberSearchLoading}
                                setIsLoading={setIsMemberSearchLoading}
                            />
                        </Tabs.Tab>
                        <Tabs.Tab label='Raw Data Search'>
                            <RawDataSearchForm
                                setResults={setRawDataSearchResults}
                            />
                        </Tabs.Tab>
                        { enableKeystoneSearch &&
                            <Tabs.Tab label='Keystone Search'>
                                <KeystoneSearchForm setData={setKeystoneSearchResultsData} isLoading={isMemberSearchLoading} setIsLoading={setIsMemberSearchLoading}/>
                            </Tabs.Tab>
                        }
                    </Tabs>
                </SizeWrapper>
                { activeIndex === 0 && memberSearchResultsData !== null && memberSearchResultsData['Count'] !== 0 &&
                    <Layout.Group space={8}>
                        <Tabs title='Member Actions' size='$sm'>
                            <Tabs.Tab label='Activity Recalculation'>
                                <ActivityRecalculationCard naviId={memberSearchResults.memberIds.naviId ?? ''}/>
                            </Tabs.Tab>
                        </Tabs>
                    </Layout.Group>
                }
                { enableKeystoneSearch && enableKeystoneEligiblityFix && activeIndex === 2 &&
                    <Layout.Group space={8}>
                        <Tabs title='Member Actions' size='$sm'>
                            <Tabs.Tab label='Fix Eligibility'>
                                <KeystoneEligibilityFixCard />
                            </Tabs.Tab>
                        </Tabs>
                    </Layout.Group>
                }
            </Layout.Group>
            <Layout.Space />

            { activeIndex === 0 && isMemberSearchLoading ? <div>Loading...</div> :
                activeIndex === 0 && (memberSearchResultsData === null || memberSearchResultsData['Count'] === 0) ? <div>No results found</div> :
                activeIndex === 0 && <MemberSearchResults data={memberSearchResults}/>
            }

            { activeIndex === 1 && (
                <div style={{ paddingBottom: '30px', paddingTop: '5px' }}>
                    {/* TODO: Add collapse all button */}
                        {rawDataSearchResults ? (
                            <RawDataResults results={rawDataSearchResults} />
                        ) : (
                            <div>No results found</div>
                        )}
                </div>
            )}

            { activeIndex === 2 && isMemberSearchLoading ? <div>Loading...</div> :
                activeIndex === 2 && (keystoneSearchResultsData === null || keystoneSearchResultsData['Count'] === 0) ? <div>No results found</div> :
                activeIndex === 2 && <KeystoneSearchResults data={keystoneSearchResults}/>
            }
            </PageBody>
        </ThemeProvider>
    );
};


const SizeWrapper = styled.main`
    width: 700px;
`;

export default Home;
