import styled from 'styled-components';
import { Popover } from '@abyss/web/ui/Popover';
import { iconColor } from '../helpers/utils';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

export interface InlinePopoverProps {
    title?: string;
    content?: string | JSX.Element | null;
    size?: string;
    displayed?: boolean;
    icon?: string;
    width?: string | number;
    endDate?: string;
};

const InlinePopover: React.FC<InlinePopoverProps> = ({ title, content, size = '$sm', displayed = true, icon = 'info', width = 260, endDate }) => {
    if(endDate && Math.round((new Date(endDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) < 0) {
        return null;
    }
    return (
        <>
            {displayed && (title || content) &&
                <DisplayWrapper>
                    <Popover
                        title={title} //TODO: future feature
                        content={content}
                        width={width}
                    >
                        <IconSymbol icon={icon} size={size} variant='outlined' color={iconColor(icon)}/>
                    </Popover>
                </DisplayWrapper>
            }
        </>
    );
};

InlinePopover.defaultProps = {
    content: undefined,
    title: undefined,
    size: '$sm',
    displayed: true,
    icon: 'info',
    width: undefined,
};

const DisplayWrapper = styled.div`
    display: inline-block;
    margin-left: 2px;
`;

export default InlinePopover;
