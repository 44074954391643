import { useCallback, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useToast } from '@abyss/web/hooks/useToast';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@abyss/web/ui/Button';
import { useForm } from '@abyss/web/hooks/useForm';
import { DataType } from '../constants/challengeTypes';
import { MemberIdType } from '../constants/types';
import { processKeystoneSearch, SearchFlags } from '../services/keystoneSearch';
import { TopMarginWrapper } from '../styles/styles';
import { KeystoneSearchFormState, useFormStore } from '../store/formStore';

export interface KeystoneSearchFormProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setData: (data: any) => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};


const KeystoneSearchForm: React.FC<KeystoneSearchFormProps> = ({ setData, isLoading, setIsLoading }) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();
    const ldClient = useLDClient();

    const enableChallengeConsoleLogging = ldClient?.variation('enable_ss_challenge_console_logging', false) as boolean;
    const isKeystoneSearchReleased = ldClient?.variation('release_ss_keystone_search', false) as boolean;
    const isKeystoneEligibilityEnabled = ldClient?.variation('release_ss_keystone_eligibility', false) as boolean;
    
    // Get form state from Zustand store
    const { keystoneSearchForm, setKeystoneSearchForm, updateKeystoneSearchForm } = useFormStore();

    const defaultSearchValues = {
        idValue: '',
        idType: MemberIdType.EID,
        dataType: [DataType.MPR],
    };

    // Initialize the form with values from the Zustand store or default values
    const form = useForm({
        defaultValues: keystoneSearchForm || defaultSearchValues,
    });
    
    // Initialize the Zustand store if it's empty
    useEffect(() => {
        if (!keystoneSearchForm) {
            setKeystoneSearchForm(defaultSearchValues);
        }
    }, [keystoneSearchForm, setKeystoneSearchForm]);

    const onSearch = useCallback(async (data : KeystoneSearchFormState, e : Event) => {
        setIsLoading(true);
        e.preventDefault();

        if(!token) {
            toast.show({
                message: 'User not authenticated',
                variant: 'error',
                autoClose: false
            });
            setIsLoading(false);
            return;
        }
        
        // Update the form store with the latest values
        updateKeystoneSearchForm(data);
        
        // Create SearchFlags object with the feature flags
        const searchFlags: SearchFlags = {
            challengeConsoleLogging: enableChallengeConsoleLogging,
            keystoneEligibilityEnabled: isKeystoneEligibilityEnabled
        };
        
        // Pass SearchFlags to the search function
        processKeystoneSearch(token, data, toast, setIsLoading, setData, searchFlags);
        if (enableChallengeConsoleLogging) console.log(data);
        if (enableChallengeConsoleLogging) console.log(`Keystone Eligibility Feature Flag is ${isKeystoneEligibilityEnabled ? 'enabled' : 'disabled'}`);
    }, [token, toast, setData, setIsLoading, updateKeystoneSearchForm, isKeystoneEligibilityEnabled]);

    return (
        !isKeystoneSearchReleased ? (
            <TopMarginWrapper>
                Keystone search is not yet released
            </TopMarginWrapper>
        ) : (
            <FormProvider state={form} onSubmit={onSearch} onError={undefined}>
                <Layout.Group alignItems='top'>
                    <TextInput
                        label='Enterprise ID'
                        placeholder='Enterprise ID'
                        model='idValue'
                        isClearable
                        validators={{ required: true }}
                        width='400px'
                    />
                </Layout.Group>
                <TopMarginWrapper>
                    <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Searching' >
                        Search
                    </Button>
                </TopMarginWrapper>
            </FormProvider>
        )
    );
};

export default KeystoneSearchForm;
