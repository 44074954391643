import axios, { AxiosError } from 'axios';

// Define the types for the response data
export interface EffectiveDates {
  endDate: string | null;
  startDate: string | null;
}

export interface MembershipGroupData {
  groupNumber: string | null;
  masterGroup: string | null;
  effectiveDates: EffectiveDates | null;
}

export interface TerminationReason {
  standardCode: string | null;
}

export interface LineOfBusiness {
  code: string | null;
  description: string | null;
  standardCode: string | null;
  standardDescription: string | null;
}

export interface CustomerAccount {
  lineOfBusiness: LineOfBusiness | null;
}

export interface Membership {
  effectiveDate: string | null;
  segmentId: string | null;
  hContractId: string | null;
  packageBenefitPlanCode: string | null;
  terminationDate: string | null;
  terminationReason: TerminationReason | null;
  customerAccount: CustomerAccount | null;
  membershipGroupData: MembershipGroupData[] | null;
  creationDate: string | null;
}

export interface Eligibility {
  memberships: Membership[] | null;
}

export interface EligibilityResponse {
  data: {
    eligibilities: Eligibility[] | null;
  };
}

export interface GraphQLResult {
  status: 'success' | 'error';
  message: string;
  data?: EligibilityResponse;
}

/**
 * Fetch eligibility data from the HCP GraphQL API via a Lambda proxy
 * @param token The authentication token
 * @param householdId The household identifier to query
 * @param includeFields Optional array of fields to include in the response
 * @returns A promise that resolves to the GraphQL result
 */
export const fetchEligibilityByHouseholdId = async (
  token: string,
  householdId: string,
  includeFields?: string[]
): Promise<GraphQLResult> => {
  try {
    // Convert parameters to URL-encoded format to match other API calls
    const requestData = new URLSearchParams();
    requestData.append('query_type', 'eligibilityByHouseholdId');
    requestData.append('parameters', JSON.stringify({ householdId }));
    
    // Only add include_fields if it's defined
    if (includeFields) {
      requestData.append('include_fields', JSON.stringify(includeFields));
    }
    
    // Call our Lambda proxy with URL-encoded data
    const response = await axios.post(
      `${process.env.REACT_APP_INVOKE_URL}/hcp/graphql`,
      requestData.toString(),
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    // Check for errors in the response
    if (response.data.status === 'error') {
      return {
        status: 'error',
        message: response.data.message || 'Error fetching eligibility data'
      };
    }

    return {
      status: 'success',
      message: 'Successfully retrieved eligibility data',
      data: response.data.data
    };
  } catch (error) {
    let errorMessage = 'Unknown error occurred';
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      errorMessage = axiosError.message;
      
      // Check for response data that might contain more specific error information
      if (axiosError.response?.data) {
        const responseData = axiosError.response.data as { message?: string };
        if (responseData.message) {
          errorMessage = responseData.message;
        }
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    
    return {
      status: 'error',
      message: `Error fetching eligibility data: ${errorMessage}`
    };
  }
};

/**
 * Fetch eligibility data for multiple household IDs
 * @param token The authentication token
 * @param householdIds Array of household identifiers to query
 * @param includeFields Optional array of fields to include in the response
 * @returns A promise that resolves to an array of GraphQL results
 */
export const fetchEligibilityForMultipleHouseholds = async (
  token: string,
  householdIds: string[],
  includeFields?: string[]
): Promise<GraphQLResult[]> => {
  // Create an array of promises for all household IDs
  const promises = householdIds.map(householdId => {
    // Wrap each API call in a promise that resolves with either the result or an error object
    return fetchEligibilityByHouseholdId(token, householdId, includeFields)
      .catch(error => ({
        status: 'error' as const,
        message: `Failed to fetch eligibility for household ID ${householdId}: ${error instanceof Error ? error.message : 'Unknown error'}`
      }));
  });
  
  // Execute all promises in parallel and return the results
  return Promise.all(promises);
};

/**
 * Available fields for eligibility queries
 */
export enum EligibilityField {
  EFFECTIVE_DATE = 'effectiveDate',
  SEGMENT_ID = 'segmentId',
  H_CONTRACT_ID = 'hContractId',
  PACKAGE_BENEFIT_PLAN_CODE = 'packageBenefitPlanCode',
  TERMINATION_DATE = 'terminationDate',
  TERMINATION_REASON = 'terminationReason',
  LINE_OF_BUSINESS = 'lineOfBusiness',
  MEMBERSHIP_GROUP_DATA = 'membershipGroupData',
  CREATION_DATE = 'creationDate'
}
