import { useState, useCallback, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useToast } from '@abyss/web/hooks/useToast';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useForm } from '@abyss/web/hooks/useForm';
import { TopMarginWrapper } from '../styles/styles';
import { KeystoneIdType } from '../constants/types';
import { triggerDatadogWorkflow, getWorkflowExecutionStatus } from '../services/datadog';
import styled from 'styled-components';

export interface KeystoneEligibilityFixCardProps {
    workflowId?: string;
    enterpriseId?: string;
};

interface FormData {
    idValue: string;
    idType: KeystoneIdType;
}

const ID_TYPE_OPTIONS = [
    { value: KeystoneIdType.EID, label: 'Enterprise ID (EID)' },
    { value: KeystoneIdType.HHID, label: 'Household ID (HHID)' },
    { value: KeystoneIdType.MBI, label: 'MBI' }
];

const KeystoneEligibilityFixCard: React.FC<KeystoneEligibilityFixCardProps> = ({
    workflowId = process.env.REACT_APP_DATADOG_DEFAULT_WORKFLOW_ID,
    // enterpriseId = '',
  }) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();

    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<{
      success?: boolean;
      message?: string;
    } | null>(null);
    const [executionId, setExecutionId] = useState<string | null>(null);
    const [countdown, setCountdown] = useState(5);
    const [polling, setPolling] = useState(false);

    const defaultValues = {
        idValue: '',
        idType: KeystoneIdType.EID
    };

    const form = useForm({
        defaultValues,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFix = useCallback(async (data : FormData, e : Event) => {
        if (!workflowId) {
          setResult({
            success: false,
            message: 'No workflow ID configured. Please set REACT_APP_DATADOG_DEFAULT_WORKFLOW_ID in your environment.'
          });
          return;
        }
        setIsLoading(true);
        setResult(null);
        e.preventDefault();


        try {
            if (!token) {
                toast.show({
                    message: 'User not authenticated',
                    variant: 'error',
                    autoClose: false
                });
                setIsLoading(false);
                return;
            }

            const response = await triggerDatadogWorkflow(
                workflowId,
                token,
                {
                    id_csv_list: data.idValue,
                    id_type: data.idType,
                    send_email: true
                }
            );

            if (response.success && response.data?.data?.data?.id) {
                setExecutionId(response.data.data.data.id);
                setPolling(true);
                setCountdown(5);
                setResult({
                    success: true,
                    message: 'Workflow triggered, checking status...'
                });
            } else {
                setResult({
                    success: false,
                    message: `Failed to trigger workflow: ${response.error}`
                });
            }
        } catch (error) {
            setResult({
                success: false,
                message: `Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`
            });
        } finally {
            setIsLoading(false);
        }
    }, [token, toast]);

    // Effect for polling workflow status
    useEffect(() => {
        let timer: NodeJS.Timeout;
        let countdownTimer: NodeJS.Timeout;

        const checkStatus = async () => {
            if (!workflowId || !executionId || !token || !polling) return;

            try {
                const statusResponse = await getWorkflowExecutionStatus(workflowId, executionId, token);
                if (!statusResponse.data) {
                    throw new Error('No status data received');
                }

                const attributes = statusResponse.data.data.data.attributes;
                const status = attributes.instanceStatus;
                if (status.detailsKind === 'SUCCEEDED') {
                    setPolling(false);
                    const startTime = new Date(attributes.startTimestamp);
                    const duration = attributes.endTimestamp
                        ? Math.round((new Date(attributes.endTimestamp).getTime() - startTime.getTime()) / 1000)
                        : 0;
                    setResult({
                        success: true,
                        message: `Workflow completed successfully in ${duration} seconds!`
                    });
                } else if (status.detailsKind === 'FAILED') {
                    setPolling(false);
                    setResult({
                        success: false,
                        message: `Workflow failed: ${status.displayName}`
                    });
                } else {
                    // Still running, show status
                    setResult({
                        success: true,
                        message: `Workflow status: ${status.displayName}`
                    });
                    setCountdown(5);
                }
            } catch (error) {
                setPolling(false);
                setResult({
                    success: false,
                    message: 'Error checking workflow status: ' + (error instanceof Error ? error.message : 'Unknown error')
                });
            }
        };

        if (polling) {
            // Initial status check
            checkStatus();

            // Status check timer (every 5 seconds)
            timer = setInterval(checkStatus, 5000);

            // Countdown timer (updates every second)
            countdownTimer = setInterval(() => {
                setCountdown(prev => prev > 1 ? prev - 1 : 5);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
            clearInterval(countdownTimer);
        };
    }, [polling, executionId, token]);

    return (
        <FormProvider state={form} onSubmit={onFix} onError={undefined}>
            <Layout.Group alignItems='top'>
                <TextInput
                    label='ID'
                    placeholder='ID Value'
                    model='idValue'
                    isClearable
                    // onChange={(e : ChangeEvent<HTMLInputElement>) => autoIdType(e.target.value, form)}
                    validators={{ required: true }}
                    width='200px'
                />
                <SelectInput
                    label='ID Type'
                    model='idType'
                    validators={{ required: true }}
                    width='200px'
                    options={ID_TYPE_OPTIONS}
                />
            </Layout.Group>
            <TopMarginWrapper>
                <Button type='submit' isLoading={isLoading} disabled={isLoading || polling} ariaLoadingLabel='Resolving' >
                    Resolve
                </Button>
            </TopMarginWrapper>

            {result && (
                <ResultContainer success={result.success}>
                    {result.message}
                    {polling && (
                        <div style={{ marginTop: '8px', color: '#666' }}>
                            Checking status in {countdown} seconds...
                        </div>
                    )}
                </ResultContainer>
            )}
        </FormProvider>
    );
};

const ResultContainer = styled.div<{ success?: boolean }>`
  padding: 12px;
  border-radius: 4px;
  background-color: ${props => props.success ? 'rgba(0, 128, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)'};
  color: ${props => props.success ? 'green' : 'red'};
  border: 1px solid ${props => props.success ? 'green' : 'red'};
`;

export default KeystoneEligibilityFixCard;
